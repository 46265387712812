import {
	tdProps,
	thProps,
	cardTitleProps,
	ulsTitleProps,
	ulsContentProps,
} from '../../common';

export const coursePrize = {
	title: {
		text: {children: '단체상', ...cardTitleProps},
	},
	table: {
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '순위'},
			{...thProps, children: '시상'},
			{...thProps, children: '비고'},
		],
		trs: [
			[
				{
					...tdProps,
					children: '최다 단체 참가상\n(풀, 10km, 하프)',
					rowSpan: '5',
				},
				{...tdProps, children: '1위'},
				{...tdProps, children: '상장/ 시상금 30만원'},
				{
					...tdProps,
					children: ``,
					rowSpan: '5',
				},
			],
			[
				{...tdProps, children: '2위'},
				{...tdProps, children: '상장/ 시상금 20만원'},
			],
			[
				{...tdProps, children: '3위'},
				{...tdProps, children: '상장/ 시상금 20만원'},
			],
			[
				{...tdProps, children: '4위'},
				{...tdProps, children: '상장/ 시상금 10만원'},
			],
			[
				{...tdProps, children: '5위'},
				{...tdProps, children: '상장/ 시상금 10만원'},
			],
			[
				{
					...tdProps,
					children: '최다 단체 참가상\n(5km)',
					rowSpan: '5',
				},
				{...tdProps, children: '학교 1위'},
				{...tdProps, children: '상장/ 시상금 15만원'},
				{
					...tdProps,
					children: ``,
					rowSpan: '5',
				},
			],
			[
				{...tdProps, children: '학교 2위'},
				{...tdProps, children: '상장/ 시상금 15만원'},
			],
			[
				{...tdProps, children: '일반부문 1위'},
				{...tdProps, children: '상장/ 시상금 15만원'},
			],
			[
				{...tdProps, children: '일반부문 2위'},
				{...tdProps, children: '상장/ 시상금 15만원'},
			],
			[
				{...tdProps, children: '일반부문 3위'},
				{...tdProps, children: '상장/ 시상금 15만원'},
			],
		],
	},
};

export const prizeInfo = {
	title: {
		text: {children: '개인 시상', ...cardTitleProps},
	},
	table: {
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '순위'},
			{...thProps, children: '시상'},
			{...thProps, children: '비고'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스 부문\n(남/여)', rowSpan: '6'},
				{...tdProps, children: '1위'},
				{...tdProps, children: '40만원, 상장, 트로피'},
				{
					...tdProps,
					children: `건타임`,
					rowSpan: '5',
				},
			],
			[
				{...tdProps, children: '2위'},
				{...tdProps, children: '35만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '3위'},
				{...tdProps, children: '30만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '4위'},
				{...tdProps, children: '25만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '5위'},
				{...tdProps, children: '20만원, 상장, 트로피'},
			],
			[
				{
					...tdProps,
					children:
						'연대별 1~5위\n(20대/30대/40대/50대/60대/70대 이상)',
				},
				{...tdProps, children: '지역상품권 2만원, 상장'},
				{
					...tdProps,
					children: `넷타임`,
					rowSpan: '1',
				},
			],
			[
				{...tdProps, children: '하프코스 부문\n(남/여)', rowSpan: '6'},
				{...tdProps, children: '1위'},
				{...tdProps, children: '30만원, 상장, 트로피'},
				{
					...tdProps,
					children: `건타임`,
					rowSpan: '5',
				},
			],
			[
				{...tdProps, children: '2위'},
				{...tdProps, children: '25만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '3위'},
				{...tdProps, children: '20만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '4위'},
				{...tdProps, children: '15만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '5위'},
				{...tdProps, children: '10만원, 상장, 트로피'},
			],

			[
				{
					...tdProps,
					children:
						'연대별 1~5위\n(20대/30대/40대/50대/60대/70대 이상)',
				},
				{...tdProps, children: '지역상품권 2만원, 상장'},
				{
					...tdProps,
					children: `넷타임`,
					rowSpan: '',
				},
			],
			[
				{...tdProps, children: '10km코스 부문\n (남/여)', rowSpan: '6'},
				{...tdProps, children: '1위'},
				{...tdProps, children: '30만원, 상장, 트로피'},
				{
					...tdProps,
					children: ``,
					rowSpan: '5',
				},
			],
			[
				{...tdProps, children: '2위'},
				{...tdProps, children: '25만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '3위'},
				{...tdProps, children: '20만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '4위'},
				{...tdProps, children: '15만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '5위'},
				{...tdProps, children: '10만원, 상장, 트로피'},
			],

			[
				{
					...tdProps,
					children:
						'연대별 1~5위\n(20대/30대/40대/50대/60대/70대 이상)',
				},
				{...tdProps, children: '지역상품권 2만원, 상장'},
				{
					...tdProps,
					children: `넷타임`,
					rowSpan: '1',
				},
			],
			[
				{...tdProps, children: '5km코스 부문\n (남/여)'},
				{
					...tdProps,
					children: '1~5위',
				},
				{...tdProps, children: '상장 /  지역상품권 2만원권'},
				{
					...tdProps,
					children: `건타임`,
				},
			],
		],
	},
};
export const refundPolicy = {
	title: {
		text: {children: '유의사항', ...cardTitleProps},
	},
	uls: [
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'정해진 종목별 출발시간 이전에 출발하거나 10분이 지난 후 출발하는 분들은 시상에서 제외됩니다.',
					},
				},
			],
		},
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'엘리트선수는 선수해지후 4년이 지난 후 입상 가능합니다.',
					},
				},
			],
		},
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'주최 측 사정에 의해 일정이 변경될 수 있으며 일정 인원 미달시 시상내역이 변경될 수 있습니다.',
					},
				},
			],
		},
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'대회 당일 시상식이 진행되며, 시상식 미참석하신 분은 요청시 시상품이 택배비 본인 부담으로 배송됩니다.',
					},
				},
			],
		},
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'시상금은 세액공제후 대회종료 3주이내 입금됩니다. (대회종료 후 상금 입금안내 문자보냄)',
					},
				},
			],
		},
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'대회 마감 이후 종목변경 / 제한시간 이후 완주자는 시상에서 제외됩니다.',
					},
				},
			],
		},
	],
};
export const priceGroup = {
	uls: [
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'단체 시상금은 대회 종료 후 2주 이내에 계좌이체 됩니다.',
					},
				},
			],
		},
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'단체 참가 특전 : 동호회 부스 설치 \n- 단체 25명 이상 참가팀 (25명이상 단체팀이 많아 텐트공간이 부족할 경우 참가 인원수가 많은 단체 팀에 우선 제공될 수 있습니다.)',
					},
				},
			],
		},
	],
};
