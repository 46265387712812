import React from 'react';
import {invite, imageProps, inviteRight, inviteDetail} from './data';
import * as S from './style';

import {BreadCrumb, CardSection} from '../../../Components/Template';

import {Text, Container, Image} from '../../../Components/Atom';

const Invite = () => {
	return (
		<Container>
			<BreadCrumb depths={['HOME', '대회안내', '초대의 글']} />
			<CardSection {...invite} />
			<S.CardWrapper>
				<Image {...inviteRight.course} />
				<div>
					<Text {...inviteRight.inviteBox.title} />
					<Text {...inviteRight.inviteBox.content} />
					<Text {...inviteRight.inviteBox.author} />
				</div>
			</S.CardWrapper>
		</Container>
	);
};

export default Invite;
