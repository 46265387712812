import React from 'react';
import {
	mrnamprofile,
	imageProps,
	profileimg,
	inviteDetail,
	profileHistoryWrap,
	newsWrap,
	newsWrap01,
	newsWrap02,
	newsWrap03,
	newsWrap04,
	newsWrap05,
	newsWrap06,
	newsWrap07,
} from './data';
import {
	ProfileWrapper,
	ProfileImg,
	NamTxt,
	ProfileImgBig,
	ProfileHistory,
} from './style';
import * as S from './style';
import {HistoryList} from '../../../Components/Organism';

import {BreadCrumb, CardSection} from '../../../Components/Template';

import {Text, Container, Image} from '../../../Components/Atom';

const Mrnam = () => {
	return (
		<Container>
			<BreadCrumb depths={['HOME', '남승룡 소개', '마라톤영웅남승룡']} />
			<CardSection {...mrnamprofile} />
			<ProfileWrapper>
				<ProfileImg>
					<Image {...profileimg.profileimg01} />
					<Image {...profileimg.profileimg02} />
				</ProfileImg>
				<NamTxt>
					{profileHistoryWrap.map((description) => (
						<HistoryList {...description} />
					))}
				</NamTxt>
			</ProfileWrapper>
			<ProfileImgBig>
				<Image {...profileimg.profileimg03} />
			</ProfileImgBig>
			<ProfileHistory>
				<Text {...newsWrap.inviteBox.title} />
				<Text {...newsWrap.inviteBox.content} />
				<Text {...newsWrap.inviteBox.author} />
			</ProfileHistory>
			<CardSection {...newsWrap01} />
			<ProfileHistory>
				<Text {...newsWrap01.inviteBox.title} />
				<Text {...newsWrap01.inviteBox.content} />
				<Text {...newsWrap01.inviteBox.author} />
			</ProfileHistory>
			<ProfileHistory>
				<Text {...newsWrap02.inviteBox.title} />
				<Text {...newsWrap02.inviteBox.content} />
				<Text {...newsWrap02.inviteBox.author} />
			</ProfileHistory>
			<ProfileHistory>
				<Text {...newsWrap04.inviteBox.title} />
				<Text {...newsWrap04.inviteBox.content} />
				<Text {...newsWrap04.inviteBox.author} />
			</ProfileHistory>
			<ProfileHistory>
				<Text {...newsWrap05.inviteBox.title} />
				<Text {...newsWrap05.inviteBox.content} />
				<Text {...newsWrap05.inviteBox.author} />
			</ProfileHistory>
			<ProfileHistory>
				<Text {...newsWrap06.inviteBox.title} />
				<Text {...newsWrap06.inviteBox.content} />
				<Text {...newsWrap06.inviteBox.author} />
			</ProfileHistory>
			<ProfileHistory>
				<Text {...newsWrap07.inviteBox.title} />
				<Text {...newsWrap07.inviteBox.content} />
				<Text {...newsWrap07.inviteBox.author} />
			</ProfileHistory>
		</Container>
	);
};

export default Mrnam;
