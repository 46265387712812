import React from 'react';
import {
	imagePropsTitle,
	imageProps,
	limitTime,
	description,
	allocateDescription,
} from './data';
import * as S from './style';
import {BreadCrumb, CardSection} from '../../../Components/Template';
import {
	Container,
	Image,
	MapCourse,
	MapCourseHalf,
	MapCourseTen,
	MapCourseFive,
} from '../../../Components/Atom';
import {ContentTitle} from '../../../Components/Organism';
import {TitleList} from '../../../Components/Organism';

const Course = () => {
	return (
		<Container>
			<BreadCrumb depths={['HOME', '대회코스']} />
			<S.Container>
				<S.ImgWrapper>
					<S.CardWrapper>
						<MapCourse />
					</S.CardWrapper>
					<S.CardWrapper>
						<MapCourseHalf />
					</S.CardWrapper>
					<S.CardWrapper>
						<MapCourseTen />
					</S.CardWrapper>
					<MapCourseFive />
				</S.ImgWrapper>
			</S.Container>
		</Container>
	);
};

export default Course;
