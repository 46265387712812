import React from 'react';
import {colorPalette} from '../../../config';
import {Container, HiddenRadio, Label} from './style';
import {BsCheckLg} from 'react-icons/bs';
const Radio = ({
	children,
	border,
	name,
	color,
	onChange,
	checked,
	value,
	Icon,
	isDisabled,
}) => {
	let fakeProps = {
		target: {
			name,
			value,
		},
	};
	// 코스 비활성화
	// if (fakeProps.target.value == "풀코스") {isDisabled = true}
	// if (fakeProps.target.value == "하프코스") {isDisabled = true}
	// if (fakeProps.target.value == "10km코스") {isDisabled = true}
	// if (fakeProps.target.value == "5km코스") {isDisabled = true}
	return (
		<Container
			onClick={(e) => {
				if (!isDisabled) {
					onChange(fakeProps);
				}
			}}
			isDisabled={isDisabled}
			checked={checked}
			border={border}
		>
			<HiddenRadio
				name={name}
				value={value}
				type='radio'
				onChange={(e) => onChange(e)}
				checked={checked}
				disabled={isDisabled}
			/>
			<Label name={name}>{children}</Label>
			<BsCheckLg
				style={{margin: 'auto 1rem auto auto'}}
				color={
					checked ? colorPalette.secondary : colorPalette.placeholder
				}
			/>
		</Container>
	);
};

export default Radio;
