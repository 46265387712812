import styled from 'styled-components';

import {colorPalette, defaultSize, viewSize} from '../../../config';
export const Container = styled.div`
	width: 100%;
	background-color: ${colorPalette.primary};
	font-family: Gmarket;
`;

export const FooterWrapper = styled.div`
	margin: auto;
	max-width: ${defaultSize.desktopContainer};
	padding: 2.75rem 0;
	width: 98%;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
	align-items: flex-end;
	@media screen and (max-width: ${viewSize.tablet}) {
		margin-bottom: 0;
	}
`;

export const TitleWrapper = styled.div`
	margin-bottom: 16px;
	> span {
		margin-left: 17px;
	}
	@media screen and (max-width: ${viewSize.tablet}) {
		text-align: right;
	}
	@media screen and (max-width: ${viewSize.mobile}) {
		display: flex;
		flex-direction: column;
	}
`;

export const ContactWrapper = styled.div`
	margin: auto 0 auto 1rem;
	display: flex;
	flex-direction: row;
	> span {
		margin-left: 17px;
	}
	@media screen and (max-width: ${viewSize.tablet}) {
		margin: auto 0 0.5rem auto;
		display: flex;
		flex-direction: column;
		text-align: right;
	}
	@media screen and (max-width: ${viewSize.mobile}) {
	}
`;

export const InfoWrapper = styled.div`
	margin: auto 0 auto 1rem;

	@media screen and (max-width: ${viewSize.tablet}) {
		margin: auto 0 1rem auto;
		text-align: right;
	}
`;

export const CopyWrapper = styled.div`
	margin: auto 0 auto auto;

	@media screen and (max-width: ${viewSize.tablet}) {
		margin: auto 0 0.5rem auto;
	}
	@media screen and (max-width: ${viewSize.mobile}) {
		text-align: right;
	}
`;

export const AdminWrapper = styled.div`
	margin: auto 0 auto auto;

	@media screen and (max-width: ${viewSize.tablet}) {
		margin: auto 0 auto auto;
	}
`;
