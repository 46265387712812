import React from 'react';
import {Text, Ul} from '../../Atom';
import {HistoryLi} from './style';
import * as S from './style';

const HistoryList = ({title, ul, padding, bgColor}) => {
	const titleProps = {
		padding,
		bgColor,
	};

	return (
		<S.Container>
			<S.HistoryWrapper {...titleProps}>
				<Text {...title} />
			</S.HistoryWrapper>
			<Ul {...ul} />
		</S.Container>
	);
};

export default HistoryList;
