import {fontWeight, colorPalette, fontSize} from '../../../config';
import {
	cardTitleProps,
	ulsTitleProps,
	ulsContentProps,
	tdProps,
	thProps,
} from '../../common';

export const summary = {
	title: {
		text: {...cardTitleProps, children: '대회개요'},
	},
	uls: [
		{
			listStyle: 'title',
			items: [
				{
					title: {...ulsTitleProps, children: '대회명'},
					content: {
						...ulsContentProps,
						children: '제24회 순천 남승룡마라톤대회',
						fontWeight: fontWeight.bold,
					},
				},
				{
					title: {...ulsTitleProps, children: '일시'},
					content: {
						...ulsContentProps,
						children: '2024년 11월 09일(토) 오전9시',
					},
				},
				{
					title: {...ulsTitleProps, children: '접수마감'},
					content: {
						...ulsContentProps,
						children: '2024년 10월 18일(금) 18:00까지 선착순 마감',
					},
				},
				{
					title: {...ulsTitleProps, children: '장소'},
					content: {
						...ulsContentProps,
						children:
							'순천팔마종합운동장 (전라남도 순천시 팔마로 333)',
					},
				},
				{
					title: {...ulsTitleProps, children: '종목'},
					content: {
						...ulsContentProps,
						children:
							'풀코스(500명), 하프코스(900명), 10km(2,500명), 5km(3,000명)\n *제한인원이 초과될 시 접수가 조기 종료될 수 있습니다.',
					},
				},
				{
					title: {...ulsTitleProps, children: '참가비'},
					content: {
						...ulsContentProps,
						children: `- 풀코스(40,000원)\n- 하프코스(35,000원)\n- 10km코스(35,000원)\n- 5km코스(15,000원)`,
					},
				},
				{
					title: {...ulsTitleProps, children: '참가자격'},
					contents: [
						{
							...ulsContentProps,
							children:
								'- 풀코스 : 만 18세 이상 남녀(2006년 11월 9일 이전 출생자)\n- 하프코스 : 만 18세 이상 남녀(2006년 11월 9일 이전 출생자)\n- 10km코스 : 중학생 이상\n- 5km : 연령제한없음(단 미취학아동은 보호자 동반 시 가능)',
						},
						//	{
						//	...ulsContentProps,
						//	children: `대한육상경기연맹 등록선수 및 선수등록 말소 후 2년 경과되지 않은 자는 참가할 수 없습니다.`,
						//	fontWeight: fontWeight.bold,
						//},
					],
				},
				{
					title: {...ulsTitleProps, children: '문의'},
					content: {
						...ulsContentProps,
						children: `마라톤대회 사무국 : 061-921-5500, 순천시체육회 : 061-745-5009 \n사무국 운영시간/09:00~18:00(월~금), 점심시간/12:00~13:00`,
					},
				},
				{
					title: {...ulsTitleProps, children: '주최'},
					content: {
						...ulsContentProps,
						children:
							'순천시 / 순천시체육회(남승룡마라톤조직위원회)',
					},
				},
				{
					title: {...ulsTitleProps, children: '주관'},
					content: {
						...ulsContentProps,
						children:
							'순천시 / 순천시체육회(남승룡마라톤조직위원회)',
					},
				},
				{
					title: {...ulsTitleProps, children: '안내사항'},
					content: {
						...ulsContentProps,
						children:
							'- 기록증은 모바일, 웹 기록증으로 제공됩니다. 기록증을 우편으로 받고자 할 시에는 착불로 발송해드립니다.\n- 기념품이 품절일 경우 주최 측 사정에 의해 증정이 어려울 경우 대체 사이즈 또는 다른 상품으로 변경될 수 있습니다.',
					},
				},
			],
		},
	],
};

export const way = {
	title: {
		text: {...cardTitleProps, children: '신청안내'},
	},
	uls: [
		{
			listStyle: 'circle',
			items: [
				{
					title: {...ulsTitleProps, children: '인터넷'},
					content: {
						...ulsContentProps,
						children:
							'참가자 서약서를 읽어보시고 동의하셔야 신청이 가능합니다.\n참가신청 양식에 맞도록 빠짐없이 입력하셔야 합니다.\n확인버튼 클릭 후 참가신청 명단 확인을 통해 본인 참가신청 접수 이상 유무를 확인바랍니다.\n참가신청 시 이메일주소를 작성하시면 이메일로 참가신청 완료 및 입금확인 메일이 전송됩니다.',
					},
				},
			],
		},
	],
};

export const feeTextProps = {
	base: {
		fontWeight: fontWeight.medium,
		color: colorPalette.primary,
		fontSize: {desktop: fontSize.xl},
	},
	accent: {
		fontWeight: fontWeight.bold,
		color: colorPalette.primary,
		fontSize: {desktop: fontSize.xl3},
	},
};

export const fee = {
	display: {
		desktop: 'table',
		tablet: 'table',
		mobile: 'none',
	},
	title: {
		text: {...cardTitleProps, children: '참가비 및 입금계좌'},
	},
	table: {
		display: {
			desktop: 'table',
			tablet: 'table',
			mobile: 'none',
		},
		scroll: false,
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '참가비'},
			{...thProps, children: '입금계좌'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스'},
				{...tdProps, children: '40,000'},
				{
					...tdProps,
					childrens: [
						{
							...feeTextProps.base,
							children:
								'입금은행 : 농협  / 예금주 : 순천시체육회',
						},
						{
							...feeTextProps.accent,
							children: '301-0318-6176-11',
						},
					],
					rowSpan: '4',
				},
			],
			[
				{...tdProps, children: '하프'},
				{...tdProps, children: '35,000'},
			],
			[
				{...tdProps, children: '10km'},
				{...tdProps, children: '35,000'},
			],
			[
				{...tdProps, children: '5km'},
				{...tdProps, children: '15,000'},
			],
		],
	},
};

export const mobileFee = {
	display: {
		desktop: 'none',
		tablet: 'none',
		mobile: 'table',
	},
	title: {
		text: {...cardTitleProps, children: '참가비 및 입금계좌'},
	},
	table: {
		display: {
			desktop: 'none',
			tablet: 'none',
			mobile: 'table',
		},
		scroll: false,
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '참가비'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스'},
				{...tdProps, children: '40,000'},
			],
			[
				{...tdProps, children: '하프코스'},
				{...tdProps, children: '35,000'},
			],
			[
				{...tdProps, children: '10km'},
				{...tdProps, children: '35,000'},
			],
			[
				{...tdProps, children: '5km'},
				{...tdProps, children: '15,000'},
			],
		],
	},
};

export const mobileDeposit = {
	display: {
		desktop: 'none',
		tablet: 'none',
		mobile: 'table',
	},
	title: {
		text: {...cardTitleProps, children: '참가비 및 입금계좌'},
	},
	table: {
		display: {
			desktop: 'none',
			tablet: 'none',
			mobile: 'table',
		},
		scroll: false,
		ths: [{...thProps, children: '입금계좌'}],
		trs: [
			[
				{
					...tdProps,
					childrens: [
						{
							...feeTextProps.base,
							children:
								'입금은행 : 농협  / 예금주 : 순천시체육회',
						},

						{...feeTextProps.accent, children: '301-0318-6176-11'},
					],
				},
			],
		],
	},
};

export const refundPolicy = {
	uls: [
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'동명이인 확인을 위해 입금자 이름 옆에 생년월일 6자리를 함께 기재하여 송금하십시오.',
					},
				},
			],
		},
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'참가신청자와 입금자의 성함이 다를 경우 입금자명을 정확히 작성해주시고 입금자명을 작성하지 못하였을 경우 사무국으로 확인 전화를 하여 주십시오\n문의전화 : 평일 09:00~18:00(점심시간 12:00~13:00) \n마라톤 대회 전일 및 당일은 통화가 불가능합니다. 운영본부로 방문해주시기 바랍니다. \n(마라톤대회 사무국 061-921-5500  |   순천시체육회 061-745-5009)',
					},
				},
			],
		},
	],
	// {
	// 	listStyle: 'title',
	// 	items: [
	// 		{
	// 			title: {
	// 				...ulsTitleProps,
	// 				children: '환불 신청 기간',
	// 				type: 'long',
	// 			},
	// 			content: {
	// 				...ulsContentProps,
	// 				children:
	// 					'참가 접수기간 중에만 가능 (참가 접수 마감 후에는 불가능)',
	// 			},
	// 		},
	// 		{
	// 			title: {
	// 				...ulsTitleProps,
	// 				children: '환불 신청방법',
	// 				type: 'long',
	// 			},
	// 			content: {
	// 				...ulsContentProps,
	// 				children: '홈페이지 자유게시판에 비밀글로 직접 신청',
	// 			},
	// 		},
	// 		{
	// 			title: {
	// 				...ulsTitleProps,
	// 				children: '환불액 지급 방법',
	// 				type: 'long',
	// 			},
	// 			content: {
	// 				...ulsContentProps,
	// 				children:
	// 					'개인 계좌를 통한 환불 처리 (환불 신청 후 7일 ~ 10일 소요)',
	// 			},
	// 		},
	// 		{
	// 			title: {
	// 				...ulsTitleProps,
	// 				children: '환불 금액',
	// 				type: 'long',
	// 			},
	// 			content: {
	// 				...ulsContentProps,
	// 				children:
	// 					'참가비의 10%를 제외한 금액 (사무국 운영, 은행 수수료 등 제반 경비)',
	// 			},
	// 		},
	// 	],
	// },
};

export const schedule = {
	title: {
		text: {...cardTitleProps, children: '대회일정'},
	},
	table: {
		ths: [
			{...thProps, children: '일자'},
			{...thProps, children: '시간'},
			{...thProps, children: '내용'},
		],
		trs: [
			[
				{...tdProps, children: '2024년 4월 14일(일)', rowSpan: '10'},
				{...tdProps, children: '08:00 ~ 08:30'},
				{...tdProps, children: '안내, 식전행사'},
			],
			[
				{...tdProps, children: '08:30'},
				{...tdProps, children: '개회선언(여수MBC)'},
			],
			[
				{...tdProps, children: '09:00'},
				{...tdProps, children: '풀코스 출발'},
			],
			[
				{...tdProps, children: '09:10'},
				{...tdProps, children: '하프코스 출발'},
			],
			[
				{...tdProps, children: '09:20'},
				{...tdProps, children: '10km코스 출발'},
			],
			[
				{...tdProps, children: '09:30'},
				{...tdProps, children: '5km코스 출발'},
			],
			[
				{...tdProps, children: '10:30'},
				{...tdProps, children: '10km코스 시상'},
			],
			[
				{...tdProps, children: '11:20'},
				{...tdProps, children: '하프코스 시상'},
			],
			[
				{...tdProps, children: '12:30'},
				{...tdProps, children: '풀코스 시상'},
			],
			[
				{...tdProps, children: '14:00'},
				{...tdProps, children: '대회 종료'},
			],
		],
		descriptions: [
			{
				content: {
					children: `행사 일정은 현장 상황에 따라 변동될 수 있습니다.`,
				},
			},
		],
	},
};
