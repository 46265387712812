import React from 'react';
import {
	invite,
	invite01,
	imageProps,
	inviteRight,
	inviteDetail,
	newsWrap,
	profileimg,
	newsWrap01,
	newsWrap02,
	newsWrap03,
} from './data';
import {ProfileImgBig, ProfileHistory} from './style';
import * as S from './style';
import {BreadCrumb, CardSection} from '../../../Components/Template';

import {Text, Container, Image} from '../../../Components/Atom';

const Invite = () => {
	return (
		<Container>
			<BreadCrumb depths={['HOME', '남승룡 소개', '추모의 글']} />
			<CardSection {...invite} />
			<ProfileHistory>
				<Text {...newsWrap.inviteBox.title} />
				<Text {...newsWrap.inviteBox.content} />
				<Text {...newsWrap.inviteBox.author} />
			</ProfileHistory>
			<ProfileHistory>
				<Text {...newsWrap01.inviteBox.title} />
				<Text {...newsWrap01.inviteBox.content} />
				<Text {...newsWrap01.inviteBox.author} />
			</ProfileHistory>
			<CardSection {...invite01} />

			<ProfileImgBig>
				<Image {...profileimg.profileimg04} />
			</ProfileImgBig>
			<ProfileHistory>
				<Text {...newsWrap02.inviteBox.title} />
				<Text {...newsWrap02.inviteBox.content} />
			</ProfileHistory>
			<ProfileHistory>
				<Text {...newsWrap03.inviteBox.title} />
				<Text {...newsWrap03.inviteBox.content} />
			</ProfileHistory>
		</Container>
	);
};

export default Invite;
