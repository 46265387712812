import {fontWeight, colorPalette, fontSize} from '../../../config';
import {
	cardTitleProps,
	ulsTitleProps,
	ulsContentProps,
	tdProps,
	thProps,
} from '../../common';
import {inviteImg} from '../../../config/images';

export const invite = {
	title: {
		text: {...cardTitleProps, children: '초대의글'},
	},
};

export const inviteDetail = {
	title: {
		type: 'p',
		fontSize: {
			desktop: '28px',
			tablet: '26px',
			mobile: '26px',
		},
		fontWeight: fontWeight.medium,
		fontFamily: 'Gmarket',
	},
	content: {
		type: 'p',
		fontSize: {
			desktop: '18px',
			tablet: '16px',
			mobile: '16px',
		},
		fontWeight: fontWeight.base,
		lineHeight: '30px',
	},
	author: {
		type: 'p',
		fontSize: {
			desktop: '18px',
			tablet: '16px',
			mobile: '16px',
		},
		fontWeight: fontWeight.base,
	},
};
export const inviteRight = {
	course: {
		src: inviteImg,
		alt: '코스',
		borderRadius: '1.375rem',
	},
	inviteBox: {
		title: {
			...inviteDetail.title,
			children: '마라톤을 사랑하는 전국의 모든 마라토너 여러분',
		},
		content: {
			...inviteDetail.content,
			children: `국가 정원과 자연 그리고 도시가 함께 공존하는 전남 제1의 도시 생태수도 순천에서 올해도 어김없이 마라톤을 아끼고 사랑하여 주시는 모든 달림이 여러분을 모시고 순천 마라톤의 자랑인 제24회 순천 남승룡마라톤 대회를 개최하게 되었습니다.

1936년 베를린 올림픽 동메달에 빛나는 세계적인 마라토너 故 남승룡 선생의 발자취와 얼을 기리고자 매년 개최되는 본 대회는 안전수칙과 절차에 만반의 준비를 하여 제24회 순천 남승룡마라톤 대회를 펼치고자 합니다.

이에, 전국에 마라톤을 사랑하고 아껴주시는 모든 달림이 여러분을 모시고 생태수도 순천에서 멋진 자연과 멋진 사람과 새로운 순천의 정취와 건강이라는 두 마리 토끼를 꼭 잡으실 수 있도록 순천시 그리고 저희 순천시 체육회(남승룡 마라톤 조직위)에서 최선의 노력을 다할 것을 약속 드리면서 여러분을 초대합니다. 제24회 순천남승룡마라톤대회의 성공적인 개최를 기원하며, 전국의 계신 마라톤 동호인 여러분의 많은 관심과 참여를 바랍니다.`,
		},
		author: {
			...inviteDetail.author,
			children: `순천시 체육회장 이상대`,
		},
	},
};
