import {colorPalette, defaultSize, viewSize} from '../../../config';
import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ImgWrapper = styled.div`
	margin-bottom: 10.5rem;
`;

export const ProfileImg = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 141px;
	> img {
		margin-bottom: 30px;
		width: 341px;
	}
	@media screen and (max-width: ${viewSize.tablet}) {
		width: 100%;
		flex-direction: row;
		justify-content: space-between;
		margin-right: 20px;
		margin-top: -70px;
		> img {
			width: 48%;
		}
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		flex-direction: column;
		> img {
			width: 100%;
		}
	}
`;
export const ProfileImgBig = styled.div`
	width: 100%;
	margin-bottom: 70px;
`;
export const ProfileWrapper = styled.div`
	display: flex;
	@media screen and (max-width: ${viewSize.tablet}) {
		flex-direction: column;
	}

	@media screen and (max-width: ${viewSize.mobile}) {
	}
`;

export const NamTxt = styled.div`
	display: flex;
	flex-direction: column;
	> div {
		display: flex;
		span {
			margin-right: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: -15px;
		}
	}
`;
export const ProfileHistory = styled.div`
	margin-bottom: 90px;
	> p {
		&:first-child {
			&:before {
				content: '▶';
				color: #ff723d;
			}
		}
	}
	> span {
		text-align: right;
		width: 100%;
		display: block;
	}
`;
