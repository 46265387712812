import styled from 'styled-components';
import {colorPalette, defaultSize, viewSize} from '../../../config';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ImgWrapper = styled.div`
	margin-bottom: 10.5rem;
`;

export const CardWrapper = styled.div`
	display: flex;
	> img {
		width: 262px;
		height: 335px;
		margin-right: 83px;
		border-radius: 0;
	}
	> div {
		p:first-child {
			margin-bottom: 22px;
		}
		p:last-child {
			text-align: right;
			margin-top: 37px;
		}
	}
	@media screen and (max-width: ${viewSize.tablet}) {
		flex-direction: column;
		align-items: center;
		> img {
			width: 189px;
			height: auto;
			margin-right: 0;
			margin-bottom: 24px;
		}
		> div {
			p:first-child {
				display: none;
			}
			p:last-child {
				margin-top: 24px;
			}
		}
	}
	@media screen and (max-width: ${viewSize.mobile}) {
	}
`;
