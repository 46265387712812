import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ImgWrapper = styled.div`
	margin-bottom: 10.5rem;
`;

export const CardWrapper = styled.div`
	margin-bottom: 60px;
`;

// export const CardSection = styled.div`
// 	margin-bottom: 3.75rem;
// `;
