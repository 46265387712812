import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ImgWrapper = styled.div`
	margin-bottom: 10.5rem;
`;

export const ProfileImg = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 141px;
	> img {
		margin-bottom: 30px;
		width: 341px;
	}
`;
export const ProfileImgBig = styled.div`
	width: 100%;
	margin-bottom: 70px;
`;
export const ProfileWrapper = styled.div`
	display: flex;
`;

export const NamTxt = styled.div`
	display: flex;
	flex-direction: column;
	> div {
		display: flex;
		span {
			margin-right: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: -15px;
		}
	}
`;
export const ProfileHistory = styled.div`
	margin-bottom: 90px;
	> p {
		&:first-child {
			margin-bottom: 20px;
			&:before {
				content: '▶';
				color: #ff723d;
			}
		}
	}
	> span {
		text-align: right;
		width: 100%;
		display: block;
	}
`;
