import styled from 'styled-components';
import {colorPalette} from '../../../config';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
`;
export const HistoryLi = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
`;

export const HistoryWrapper = styled.div``;
