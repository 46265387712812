export const markerdata = [
	{
		title: '10km 출발, 도착',
		lat: 34.93824533293566,
		lng: 127.51941208098545,
	},
	{
		title: '10km 순환',
		lat: 34.91698,
		lng: 127.52859,
	},
];
