import {colorPalette, defaultSize, viewSize} from '../../../config';

import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: ${(props) => (props.height ? props.height : '492px')};
`;
export const CourseTitle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 18px;
	margin-bottom: 30px;
	width: 123px;
	height: 32px;
	border-radius: 21px;
	background-color: #005bac;
	@media screen and (max-width: ${viewSize.mobile}) {
		font-size: 16px;
	}
`;
