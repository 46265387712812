export const markerdata = [
	{
		title: '하프코스 출발, 도착',
		lat: 34.93824533293566,
		lng: 127.51941208098545,
	},
	{
		title: '하프 반환',
		lat: 34.88831479894759,
		lng: 127.49967489193652,
	},
];
