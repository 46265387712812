export const markerdata = [
	{
		title: '5km 출발, 도착',
		lat: 34.93824533293566,
		lng: 127.51941208098545,
	},
	{
		title: '5km 반환',
		lat: 34.92974502932783,
		lng: 127.49795042110696,
	},
];
