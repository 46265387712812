export const markerdata = [
	{
		title: '풀코스 출발, 도착',
		lat: 34.93824533293566,
		lng: 127.51941208098545,
	},
	{
		title: '풀1차 반환',
		lat: 34.88105,
		lng: 127.46648,
	},
	{
		title: '풀2차 반환',
		lat: 34.84019,
		lng: 127.48477,
	},
];
