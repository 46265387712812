import React from 'react';
import {
	colorPalette,
	fontSize as configFont,
	fontWeight as configWeight,
} from '../../../config';
import {Link, Text} from '../../Atom';
import {
	Container,
	TitleWrapper,
	InfoWrapper,
	ContactWrapper,
	CopyWrapper,
	FooterWrapper,
	Section,
	AdminWrapper,
} from './style';

const defaultFontSize = {
	desktop: configFont.sm2,
	tablet: configFont.sm2,
	mobile: configFont.xs,
};

const Footer = ({
	name = '0000',
	mainname = '0000',
	companynum = '000-00-00000',
	tel = '000-0000-0000',
	fax = '000-0000-0000',
	email = '000@',
	color = colorPalette.white,
	fontSize = defaultFontSize,
	fontWeight = configWeight.medium,
}) => {
	const textProps = {
		color,
		fontSize,
		fontWeight,
	};

	return (
		<Container>
			<FooterWrapper>
				<Section>
					<TitleWrapper>
						<Text {...textProps}>{name}</Text>
						<Text {...textProps}>{mainname}</Text>
						<Text {...textProps}>{companynum}</Text>
					</TitleWrapper>
					<ContactWrapper>
						<Text {...textProps}>마라톤대회 사무국 {tel} </Text>
						<Text {...textProps}> 순천시체육회 {fax}</Text>
						<Text {...textProps}> E-mail. {email}</Text>
					</ContactWrapper>
				</Section>
				<Section>
					<CopyWrapper>
						<Text {...textProps}>
							{/* Copyright(c) {name}마라톤 All rights reserved. */}
							Copyright(c) 순천시체육회, 순천남승룡마라톤
							조직위원회 All rights reserved.
						</Text>
					</CopyWrapper>
				</Section>
				<Section>
					<AdminWrapper>
						<Link
							{...textProps}
							href={window.location.origin + '/admin/login'}
						>
							admin 페이지
						</Link>
					</AdminWrapper>
				</Section>
			</FooterWrapper>
		</Container>
	);
};

export default Footer;
