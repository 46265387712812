import styled from 'styled-components';
import {colorPalette, defaultSize, viewSize} from '../../../config';
import {Menu as AntdMenu} from 'antd';

export const Container = styled.div`
    width : 100%;
    position : fixed;
    top :0;
    background-color ${(props) =>
		props.bgColor ? props.bgColor : colorPalette.white};
    box-shadow : ${(props) =>
		props.isBoxShadow ? '0 3px 6px 0 rgba(0, 0, 0, 0.16)' : 'none'};
    display : flex;
    flex-direction: column;
    align-items : center;
	z-index: 9999;
	
`;

export const NavWrapper = styled.div`
	width: 98%;
	max-width: ${defaultSize.desktopContainer};
	display: flex;
	align-items: center;
	height: ${defaultSize.nav.desktop};
	margin: auto;

	@media screen and (min-width: ${viewSize.mobile}) and( max-width : ${viewSize.tablet}) {
		max-width: ${defaultSize.tabletContainer};
		height: ${defaultSize.nav.tablet};
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		max-width: ${defaultSize.mobileContainer};
		height: ${defaultSize.nav.mobile};
	}
`;

export const LogoWrapper = styled.img`
	width: 153px;
	height: 52px;
	cursor: pointer;
	@media screen and (max-width: ${viewSize.mobile}) {
		display: none;
	}
`;

export const MenuWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin: auto 0 auto auto;
	width: 60%;
	cursor: pointer;
	> div > a {
		font-size: 18px;
		font-weight: 500;
		font-family: Gmarket;
	}

	@media screen and (max-width: ${viewSize.tablet}) {
		display: none;
	}
`;

export const ButtonWrapper = styled.div`
	margin: auto 0 auto 2.75rem;
	width: 140px;

	@media screen and (max-width: ${viewSize.tablet}) {
		margin: auto 1.25rem auto auto;
	}

	@media screen and (max-width: ${viewSize.mobile}) {
		margin: auto auto auto 0;
	}
`;

export const IconWrapper = styled.div`
	display: none;
	cursor: pointer;
	margin: auto 0;
	@media screen and (max-width: ${viewSize.tablet}) {
		display: flex;
	}
`;

export const SubMenuContainer = styled.div`
	width: 100%;
	display: ${(props) => (props.isHidden ? 'none' : 'flex')};
	border-top: 1px solid ${colorPalette.border};

	@media screen and (max-width: ${viewSize.tablet}) {
		display: none;
	}
`;

export const SubMenuWrapper = styled.div`
	width: 100%;
	max-width: ${defaultSize.desktopContainer};
	display: flex;
	justify-content: space-between;
	margin: auto;
`;

export const SubMenu = styled.div`
	display: flex;
	flex-direction: column;
	border-right: 1px solid ${colorPalette.border};
	width: 100%;
	align-items: flex-start;

	&:last-child {
		border-right: none;
	}
`;

export const TitleWrapper = styled.div`
	margin: 1.75rem 2rem;
	> span {
		font-size: 18px;
		font-weight: 800;
		font-family: Pretendard;
	}
`;

export const Menu = styled.div`
	margin: 0 2rem 1.75rem 2rem;
	div > a {
		font-size: 16px;
		font-weight: 500;

		${(props) =>
			props.isCurrent
				? `color : ${colorPalette.secondary};`
				: `color : ${colorPalette.navColor};`}
	}
	@media only screen and (max-width: ${viewSize.mobile}) {
		margin-bottom: 15px;
	}
`;

export const MobileContainer = styled.div`
	background-color: white;
	display: none;
	height: 100vh;
	overflow: scroll;
	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (max-width: ${viewSize.tablet}) {
		display: ${(props) => (props.isHidden ? 'none' : 'flex')};
	}
`;

export const MobileNavWrapper = styled.div`
	flex-wrap: wrap;
	display: flex;
`;

export const MobileMenu = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-bottom: solid 1px #d9d9d9;
	> div {
		display: flex;
		flex-wrap: wrap;
	}
	@media screen and (max-width: ${viewSize.mobile}) {
		&:last-child {
			border-bottom: none;
		}
	}
`;

export const Fake = styled.div`
	height: 85px;
	width: 100%;

	@media screen and (max-width: ${viewSize.mobile}) {
		height: 70px;
	}
`;

export const AdminNavContainer = styled.div({
	padding: '10px 20px',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	background: '#25a3d2',
	overflow: 'auto',
});

export const AdminNavLeftBox = styled.div({
	display: 'flex',
	flexDirection: 'row',
});

export const AdminNavRightBox = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
});

export const AdminNavItem = styled.div(({isActive}) => ({
	cursor: 'pointer',
	padding: '10px 10px 5px 10px',
	fontSize: 18,
	color: '#fff',
	fontWeight: 500,
	'& a': {
		color: '#fff',
		textDecoration: 'none',
	},
	borderBottom: isActive ? '2px solid #fff' : 'none',
	margin: '0px 10px 10px 0px',
}));

export const HomepageButton = styled.div({
	cursor: 'pointer',
	borderRadius: 20,
	height: 30,
	fontSize: 18,
	padding: '0px 10px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	background: '#fff',
	color: '#25a3d2',
	margin: '0px 5px 10px 0px',
});
