import {
	colorPalette,
	fontSize,
	fontWeight,
	mainLinks,
	supporter,
	fontFamily,
} from '../../config';
import {
	participateLogoImg,
	recogLogoImg,
	infoLogoImg,
	recordLogoImg,
	notifyLogoImg,
	support1,
	support2,
	cbsImg,
	gyeongSangImg,
	jinjuImg,
	courseImg,
} from '../../config/images';

const dateTextProps = {
	title: {
		fontSize: {
			desktop: fontSize.xl5,
			tablet: '30px',
			mobile: '24px',
		},
		color: colorPalette.white,
		fontWeight: fontWeight.bold,
		shadow: `0 0 10px rgba(0, 0, 0, 0.7);`,
	},
	unit: {
		fontSize: {
			desktop: fontSize.xl2,
			tablet: '22px',
			mobile: '20px',
		},
		color: colorPalette.white,
		fontWeight: fontWeight.bold,
		opacity: 0.64,
		shadow: `0 0 10px rgba(0, 0, 0, 0.7);`,
	},
	value: {
		fontSize: {
			desktop: fontSize.xl7,
			tablet: '44px',
			mobile: '38px',
		},
		color: colorPalette.white,
		fontWeight: fontWeight.bold,
		shadow: `0 0 10px rgba(0, 0, 0, 0.7);`,
	},
};

export const firstProps = {
	titlenum: {
		type: 'p',
		children: `제24회`,
		fontSize: {
			desktop: '24px',
			tablet: '20px',
			mobile: '15px',
		},
		color: colorPalette.secondary,
		fontWeight: fontWeight.bold,
	},
	title: {
		type: 'h2',
		children: `순천남승룡마라톤`,
		fontSize: {
			desktop: '71px',
			tablet: '50px',
			mobile: '36px',
		},
		fontFamily: 'Gmarket',
		color: colorPalette.white,
		fontWeight: fontWeight.bold,
	},
	content: {
		type: 'p',
		children: `2024.11.09 (토)`,
		fontSize: {
			desktop: '35px',
			tablet: '35px',
			mobile: '18px',
		},
		color: colorPalette.white,
		fontWeight: fontWeight.bold,
	},
	button: {
		text: {
			children: '신청하기',
			fontSize: {
				desktop: '24px',
				tablet: '24px',
				mobile: '16px',
			},
			lineHeight: 2.5,
			color: colorPalette.white,
			fontWeight: fontWeight.medium,
		},
		onClick: () =>
			(window.location.href = `${window.location.origin}/participation/person`),
		isRounded: true,
		bgColor: colorPalette.darknavy,
	},
	date: {
		title: {...dateTextProps.title, children: '행사 시작까지'},
		day: {...dateTextProps.unit, children: 'DAY'},
		dayValue: {...dateTextProps.value, children: '14'},
		hour: {...dateTextProps.unit, children: 'HOUR'},
		hourValue: {...dateTextProps.value, children: '8'},
		minute: {...dateTextProps.unit, children: 'MINUTES'},
		minuteValue: {...dateTextProps.value, children: '25'},
		second: {...dateTextProps.unit, children: 'SECONDS'},
		secondValue: {...dateTextProps.value, children: '32'},
	},
};

const secondTextProps = {
	title: {
		fontSize: {
			desktop: '26px',
			tablet: '26px',
			mobile: '26px',
		},
		color: colorPalette.primary,
		fontWeight: fontWeight.medium,
		fontFamily: 'Gmarket',
	},
	content: {
		fontSize: {
			desktop: '16px',
			tablet: '16px',
			mobile: '16px',
		},
		color: colorPalette.noneBorder,
		fontWeight: fontWeight.base,
		fontFamily: 'Gmarket',
	},
	support: {
		fontSize: {
			desktop: fontSize.xl2,
			tablet: '22px',
			mobile: '15px',
		},
		color: colorPalette.base,
		fontWeight: fontWeight.semiBold,
	},
};

export const secondProps = {
	info: {
		title: {
			...secondTextProps.title,
			children: '마라톤 알아보기',
		},
		content: {
			...secondTextProps.content,
			children: `마라톤에 도움이 되는 바로가기\n정보를 안내합니다.`,
		},
		links: mainLinks,
	},
	supports: supporter,
};

export const thirdTextProps = {
	title: {
		fontSize: {
			desktop: fontSize.xl3,
			tablet: '36px',
			mobile: '26px',
		},
		fontWeight: fontWeight.medium,
		color: colorPalette.white,
		fontFamily: 'Gmarket',
	},
	description: {
		fontSize: {
			desktop: '16px',
			tablet: '16px',
			mobile: '16px',
		},
		fontWeight: fontWeight.medium,
		color: colorPalette.white,
	},
	item: {
		title: {
			fontSize: {
				desktop: '20px',
				tablet: '18px',
				mobile: '18px',
			},
			color: colorPalette.realBlack,
			fontWeight: fontWeight.bold,
			lineHeight: '30px',
		},
		content: {
			fontSize: {
				desktop: '20px',
				tablet: '16px',
				mobile: '16px',
			},
			color: colorPalette.realBlack,
			fontWeight: fontWeight.normal,
			lineHeight: '30px',
		},
		smalContent: {
			fontSize: {
				desktop: fontSize.base,
				tablet: '16px',
				mobile: '16px',
			},
			color: colorPalette.tertiary,
			fontWeight: fontWeight.medium,
		},
		accentContent: {
			fontSize: {
				desktop: '20px',
				tablet: '18px',
				mobile: '16px',
			},
			color: colorPalette.tertiary,
			fontWeight: fontWeight.normal,
			lineHeight: '30px',
		},
	},
};

export const thirdProps = {
	title: {
		textAlign: 'left',
		bgColor: colorPalette.white,
		height: '3px',
		text: {...thirdTextProps.title, children: '대회요강', type: 'p'},
		description: {
			...thirdTextProps.description,
			children: '반드시 참가 유의사항을 읽고 신청해 주세요.',
		},
	},
	first: [
		{
			title: {...thirdTextProps.item.title, children: '대회명'},
			contents: [
				{
					...thirdTextProps.item.content,
					children: `제24회 순천 남승룡마라톤대회`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '대회일시'},
			contents: [
				{
					...thirdTextProps.item.title,
					children: `2024년 11월 09일 (토)`,
				},
				{
					...thirdTextProps.item.content,
					children: `09:00 풀코스 출발\n09:10 하프코스 출발\n09:20 10km코스 출발\n09:30 5km코스 출발\n`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '집결시간'},
			contents: [
				{
					...thirdTextProps.item.content,
					children: `오전 8시`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '장소'},
			contents: [
				{
					...thirdTextProps.item.content,
					children: `순천팔마종합운동장\n(전라남도 순천시 팔마로 333)`,
				},
			],
		},
		//{
		//	title: {...thirdTextProps.item.title, children: '참가구분'},
		//	contents: [
		//		{
		//			...thirdTextProps.item.content,
		//			children: `풀코스/하프코스/10km코스/5km코스`,
		//		},
		//		{
		//			...thirdTextProps.item.content,
		//			children: `4가지 코스 중 선택 가능`,
		//	},
		//	],
		//},
	],
	second: [
		//{
		//	title: {...thirdTextProps.item.title, children: '참가자격'},
		//	contents: [
		//		{
		//			...thirdTextProps.item.content,
		//			children: `나이제한 없으며, 신체 건강한 남 / 녀`,
		//		},
		//		{
		//			...thirdTextProps.item.smalContent,
		//			children: `대한육상경기연맹 등록선수 및 선수등록 말소 후\n2년 경과되지 않은 자는 시상에서 제외됩니다.`,
		//		},
		//	],
		// },
		{
			title: {...thirdTextProps.item.title, children: '접수기간'},
			contents: [
				{
					...thirdTextProps.item.title,
					children: `2024년 10월 18일 (금) 18:00까지`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '접수방법'},
			contents: [
				{
					...thirdTextProps.item.content,
					children: `인터넷으로만 접수`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '모집인원'},
			contents: [
				{
					...thirdTextProps.item.accentContent,
					children: `풀코스(500명), 하프코스(900명),\n10km(2500명), 5km(3000명)`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '참가비'},
			contents: [
				{
					...thirdTextProps.item.content,
					children: `풀코스 - 40,000원\n하프코스 - 35,000원\n10km코스 - 35,000원\n5km코스 - 15,000원`,
				},
			],
		},
		{
			title: {...thirdTextProps.item.title, children: '입금은행'},
			contents: [
				{
					...thirdTextProps.item.content,
					children: `농협 / 예금주 : 순천시체육회\n301-0318-6176-11`,
				},
			],
		},
	],
};

const fourthTextProps = {
	title: {
		fontSize: {
			desktop: fontSize.xl3,
			tablet: '36px',
			mobile: '24px',
		},
		fontWeight: fontWeight.medium,
		color: colorPalette.black,
		fontFamily: 'Gmarket',
	},
	description: {
		fontSize: {
			desktop: '16px',
			tablet: '16px',
			mobile: ' 16px',
		},
		fontWeight: fontWeight.medium,
		color: colorPalette.engTitle,
	},
};

export const fourthProps = {
	title: {
		textAlign: 'left',
		bgColor: colorPalette.primary,
		height: '5px',
		text: {...fourthTextProps.title, children: '대회코스', type: 'p'},
		description: {
			...fourthTextProps.description,
			children: '대회 구간을 참고하시고 신청 바랍니다',
		},
	},
	course: {
		src: courseImg,
		alt: '코스',
		borderRadius: '1.375rem',
	},
};

export const modalProps = {
	isClose: true,
	imgs: [
		{src: courseImg},
		{src: ''},
	]
};
