import React, {useEffect, useRef, useState} from 'react';
import {Map, MapMarker, Polygon} from 'react-kakao-maps-sdk';
import {markerdata} from './data';
import {flagimg} from '../../../config/images';
import {CourseTitle} from './style';
import * as S from './style';

const MapCourse = ({lat = 34.89623524498667, lng = 127.49704792686417}) => {
	const [map, setMap] = useState(null);

	useEffect(() => {
		const {kakao} = window;
		const mapContainer = document.getElementById('map'); // 지도를 표시한 곳
		const location = new kakao.maps.LatLng(lat, lng);

		const mapOptions = {
			// 지도의 옵션
			center: location, //지도의 중심좌표.
			level: 7, //지도의 레벨(확대, 축소 정도)
		};

		//지도 생성
		const kakaoMap = new kakao.maps.Map(mapContainer, mapOptions);

		//마커 이미지
		const myMarker = flagimg,
			myMarkerSize = new window.kakao.maps.Size(30, 30),
			myMarkerOption = {offset: new window.kakao.maps.Point(4, 30)};

		const myMarkerPosition = new window.kakao.maps.MarkerImage(
			myMarker,
			myMarkerSize,
			myMarkerOption,
		);

		//마커 여러개 표시
		markerdata.forEach((el, i) => {
			//출발, 도착 깃발 마커
			if (i == 0) {
				var marker = new kakao.maps.Marker({
					map: kakaoMap,
					position: new kakao.maps.LatLng(el.lat, el.lng),
					image: myMarkerPosition,
				});
				marker.setMap(kakaoMap);
			} else {
				//일반 반환마커
				var originmarker = new kakao.maps.Marker({
					map: kakaoMap,
					position: new kakao.maps.LatLng(el.lat, el.lng),
				});
				originmarker.setMap(kakaoMap);
			}
			var customOverlay = new kakao.maps.CustomOverlay({
				position: new kakao.maps.LatLng(el.lat, el.lng),
				content: `<div class ="label" style="margin: -55px auto;"><span class="left"></span><span class="center" style="color: black; background-color: white; border-radius: 7px; padding: 1px 10px;  border: solid 2px #d9d9d9;
">${el.title}</span><span class="right"></span></div></div>`,
			});

			customOverlay.setMap(kakaoMap);
		});

		//컨트롤 표시
		var mapTypeControl = new kakao.maps.MapTypeControl();
		kakaoMap.addControl(
			mapTypeControl,
			kakao.maps.ControlPosition.TOPRIGHT,
		);

		// 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다
		var zoomControl = new kakao.maps.ZoomControl();
		kakaoMap.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

		//지도 범위 핀 다 보이게 재설정

		//폴리 라인 그리기
		const linePath = [
			new kakao.maps.LatLng(34.93824533293566, 127.51941208098545), //팔마종합운동장
			new kakao.maps.LatLng(34.93771, 127.51914),
			new kakao.maps.LatLng(34.93703, 127.51893),
			new kakao.maps.LatLng(34.93608, 127.51904),
			new kakao.maps.LatLng(34.9349, 127.51897),
			new kakao.maps.LatLng(34.93469, 127.51906),
			new kakao.maps.LatLng(34.93457, 127.5197),
			new kakao.maps.LatLng(34.9343, 127.52049),
			new kakao.maps.LatLng(34.93379, 127.5214),
			new kakao.maps.LatLng(34.93327, 127.52202),
			new kakao.maps.LatLng(34.93272, 127.5226),
			new kakao.maps.LatLng(34.93186, 127.52316),
			new kakao.maps.LatLng(34.9308, 127.52404),
			new kakao.maps.LatLng(34.92943, 127.52479),
			new kakao.maps.LatLng(34.92831, 127.52524),
			new kakao.maps.LatLng(34.92711, 127.52515),
			new kakao.maps.LatLng(34.9265, 127.52464),
			new kakao.maps.LatLng(34.92581, 127.52401),
			new kakao.maps.LatLng(34.92461, 127.52365),
			new kakao.maps.LatLng(34.92348, 127.52324),
			new kakao.maps.LatLng(34.92248, 127.52335),
			new kakao.maps.LatLng(34.92142, 127.52377), //2377
			new kakao.maps.LatLng(34.92086, 127.52435),
			new kakao.maps.LatLng(34.92025, 127.52536),
			new kakao.maps.LatLng(34.91951, 127.52635),
			new kakao.maps.LatLng(34.9188, 127.52706),
			new kakao.maps.LatLng(34.91796, 127.5276),
			new kakao.maps.LatLng(34.91717, 127.52826),
			new kakao.maps.LatLng(34.91683, 127.52878),
			new kakao.maps.LatLng(34.91671, 127.52938),
			new kakao.maps.LatLng(34.91664, 127.53054),
			new kakao.maps.LatLng(34.91664, 127.53182),
			new kakao.maps.LatLng(34.91652, 127.53283), //알뜰주유소
			new kakao.maps.LatLng(34.91616, 127.53404),
			new kakao.maps.LatLng(34.91556, 127.53463),
			new kakao.maps.LatLng(34.91541, 127.53437),
			new kakao.maps.LatLng(34.91144, 127.53148),
			new kakao.maps.LatLng(34.91122, 127.53105),
			new kakao.maps.LatLng(34.91095, 127.5307),
			new kakao.maps.LatLng(34.9108, 127.53026),
			new kakao.maps.LatLng(34.91069, 127.52976),
			new kakao.maps.LatLng(34.91075, 127.5295),
			new kakao.maps.LatLng(34.91062, 127.52881),
			new kakao.maps.LatLng(34.91049, 127.52843),
			new kakao.maps.LatLng(34.91242, 127.52858),
			new kakao.maps.LatLng(34.91579, 127.52857), //2024-07-26 수정코스

			new kakao.maps.LatLng(34.91581, 127.52852),
			new kakao.maps.LatLng(34.91574, 127.52345),
			new kakao.maps.LatLng(34.91571, 127.5212),
			new kakao.maps.LatLng(34.91573, 127.5203),
			new kakao.maps.LatLng(34.91571, 127.51832),
			new kakao.maps.LatLng(34.91462, 127.51799),
			new kakao.maps.LatLng(34.91353, 127.51718),
			new kakao.maps.LatLng(34.91174, 127.51563),
			new kakao.maps.LatLng(34.90938, 127.5149),
			new kakao.maps.LatLng(34.90769, 127.51452),
			new kakao.maps.LatLng(34.90565, 127.5149),
			new kakao.maps.LatLng(34.90513, 127.51486),

			new kakao.maps.LatLng(34.9049, 127.51445),
			new kakao.maps.LatLng(34.90516, 127.51373),
			new kakao.maps.LatLng(34.90651, 127.51144),
			new kakao.maps.LatLng(34.90779, 127.50895),
			new kakao.maps.LatLng(34.90873, 127.50644),

			new kakao.maps.LatLng(34.90899, 127.50578),
			new kakao.maps.LatLng(34.90975, 127.50393),
			new kakao.maps.LatLng(34.907, 127.50502),
			new kakao.maps.LatLng(34.90662, 127.50519),
			new kakao.maps.LatLng(34.90581, 127.505),
			new kakao.maps.LatLng(34.90331, 127.50416),
			new kakao.maps.LatLng(34.90234, 127.50401),
			new kakao.maps.LatLng(34.90086, 127.50438),

			new kakao.maps.LatLng(34.89601, 127.50802),

			new kakao.maps.LatLng(34.89492, 127.5086),

			new kakao.maps.LatLng(34.8942, 127.50933),

			new kakao.maps.LatLng(34.89376, 127.5097),
			new kakao.maps.LatLng(34.89357, 127.50994),

			new kakao.maps.LatLng(34.89349, 127.5099),
			new kakao.maps.LatLng(34.89308, 127.50995),
			new kakao.maps.LatLng(34.88764, 127.50897),
			new kakao.maps.LatLng(34.88669, 127.50805),
			new kakao.maps.LatLng(34.88649, 127.50545),
			new kakao.maps.LatLng(34.88644, 127.50455),
			new kakao.maps.LatLng(34.8876, 127.50068),

			new kakao.maps.LatLng(34.88799, 127.49946),
			new kakao.maps.LatLng(34.88792, 127.49743),
			new kakao.maps.LatLng(34.88649, 127.49255),
			new kakao.maps.LatLng(34.88644, 127.49001),
			new kakao.maps.LatLng(34.88591, 127.4877),
			new kakao.maps.LatLng(34.8859, 127.48712),
			new kakao.maps.LatLng(34.8861, 127.48658),

			new kakao.maps.LatLng(34.88673, 127.48563),
			new kakao.maps.LatLng(34.88312, 127.48194),
			new kakao.maps.LatLng(34.88287, 127.4813),
			new kakao.maps.LatLng(34.8829, 127.48064),
			new kakao.maps.LatLng(34.88281, 127.48056),
			new kakao.maps.LatLng(34.88195, 127.48056),
			new kakao.maps.LatLng(34.88136, 127.48017),
			new kakao.maps.LatLng(34.88052, 127.48003),
			new kakao.maps.LatLng(34.88002, 127.48019),
			new kakao.maps.LatLng(34.87925, 127.47959),
			new kakao.maps.LatLng(34.87905, 127.47905),
			new kakao.maps.LatLng(34.87824, 127.4786),
			new kakao.maps.LatLng(34.87768, 127.47857),
			new kakao.maps.LatLng(34.87745, 127.4783),
			new kakao.maps.LatLng(34.87784, 127.4766),
			new kakao.maps.LatLng(34.87865, 127.47602),
			new kakao.maps.LatLng(34.87923, 127.47428),

			new kakao.maps.LatLng(34.87747, 127.47256),
			new kakao.maps.LatLng(34.88099, 127.46721),
			new kakao.maps.LatLng(34.88124, 127.46666),
			new kakao.maps.LatLng(34.88105, 127.46648), //풀 1차 반환
			new kakao.maps.LatLng(34.87724, 127.47231),
			new kakao.maps.LatLng(34.87424, 127.47691),
			new kakao.maps.LatLng(34.87421, 127.4769),
			new kakao.maps.LatLng(34.86891, 127.48217),
			new kakao.maps.LatLng(34.86862, 127.48261),
			new kakao.maps.LatLng(34.86806, 127.48421),
			new kakao.maps.LatLng(34.86798, 127.48448),
			new kakao.maps.LatLng(34.86784, 127.48468),
			new kakao.maps.LatLng(34.86646, 127.48602),

			new kakao.maps.LatLng(34.86489, 127.48667),
			new kakao.maps.LatLng(34.86382, 127.48771),
			new kakao.maps.LatLng(34.86332, 127.48795),
			new kakao.maps.LatLng(34.86294, 127.48801),
			new kakao.maps.LatLng(34.8626, 127.48796),
			new kakao.maps.LatLng(34.85873, 127.48691),
			new kakao.maps.LatLng(34.8558, 127.48743),
			new kakao.maps.LatLng(34.85515, 127.48782),
			new kakao.maps.LatLng(34.8544, 127.48879),
			new kakao.maps.LatLng(34.85365, 127.49004),
			new kakao.maps.LatLng(34.85361, 127.49036),
			new kakao.maps.LatLng(34.85312, 127.49085),
			new kakao.maps.LatLng(34.85274, 127.49148),

			new kakao.maps.LatLng(34.85267, 127.49158),
			new kakao.maps.LatLng(34.85227, 127.4924),
			new kakao.maps.LatLng(34.85187, 127.49263),
			new kakao.maps.LatLng(34.85188, 127.49259),
			new kakao.maps.LatLng(34.85139, 127.4924),
			new kakao.maps.LatLng(34.85072, 127.49219),
			new kakao.maps.LatLng(34.85008, 127.49216),
			new kakao.maps.LatLng(34.84893, 127.49203),
			new kakao.maps.LatLng(34.84824, 127.49218),
			new kakao.maps.LatLng(34.84759, 127.49212),
			new kakao.maps.LatLng(34.84685, 127.49246),
			new kakao.maps.LatLng(34.84629, 127.49201),
			new kakao.maps.LatLng(34.846, 127.49126),
			new kakao.maps.LatLng(34.84588, 127.49119),
			new kakao.maps.LatLng(34.84496, 127.49083),
			new kakao.maps.LatLng(34.84366, 127.48948),
			new kakao.maps.LatLng(34.84305, 127.48802),
			new kakao.maps.LatLng(34.8425, 127.48744),
			new kakao.maps.LatLng(34.84229, 127.48638),
			new kakao.maps.LatLng(34.8416, 127.48587),
			new kakao.maps.LatLng(34.84049, 127.48585),
			new kakao.maps.LatLng(34.8416, 127.48587),
			new kakao.maps.LatLng(34.84049, 127.48585),
			new kakao.maps.LatLng(34.84026, 127.48531),
			new kakao.maps.LatLng(34.84019, 127.48477), //화포항 풀2차 반환
		];

		var polyline = new kakao.maps.Polyline({
			path: linePath,
			strokeWeight: 5,
			strokeColor: '#005bac',
			strokeOpacity: 1,
			strokeStyle: 'solid',
		});

		polyline.setMap(kakaoMap);
	}, []);

	return (
		<>
			<CourseTitle>풀코스</CourseTitle>
			<div
				id='map'
				style={{
					width: '100%',
					height: '960px',
				}}
			></div>
		</>
	);
};

export default MapCourse;
