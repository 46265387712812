import {
	cardTitleProps,
	ulsContentProps,
	ulsTitleProps,
	subTitleProps,
	thProps,
	tdProps,
} from '../../common';
import {colorPalette, fontWeight} from '../../../config';

export const qualification = {
	title: {
		text: {...cardTitleProps, children: '참가자 건강상태 확인'},
	},
	uls: [
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'참가자는 반드시 본인의 건강상태를 고려하여 종목을 선택해야 합니다. 참가자 스스로 본인의 건강관리에 충분히 주의를 기울이시기 바라며 대회 도중 이상이 느껴질 경우 즉시 경기를 중단하고 대회 진행요원에게 도움을 요청하시기 바랍니다. 레이스 중 사고에 대해서는 주최측에서는 어떠한 책임도 지지 않습니다. 자신의 건강을 위해 무리한 레이스는 삼가시길 바랍니다.',
					},
				},
			],
		},
	],
};

export const notice = {
	title: {
		text: {...cardTitleProps, children: '참가 기념품 관련'},
	},
	uls: [
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children: `참가자분들에게는 참가 패키지(기념품과 배번호표)를 보내드립니다. 참가패키지는 택배를 통해 배송되며, 완주메달은 배번호 확인후 지급됩니다.
풀, 하프, 10km 부문 참가자분들에게는 기록측정용 칩을 참가패키지를 통해 보내드리며, 대회 후 모바일 기록증 조회 링크 보내드립니다.
참가 신청 시 택배를 수령할 수 있는 정확한 주소와 본인 핸드폰 번호를 입력해 주시기 바랍니다.
수령하신 기념품에 이상이 있을 경우 즉시 사무국(061-921-5500)으로 연락바랍니다.`,
					},
				},
			],
		},
	],
};
export const reception = {
	title: {
		text: {...cardTitleProps, children: '현장 운영 시설'},
	},
	uls: [
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'대회장 내에 탈의실 및 물품보관소가 운영됩니다.',
					},
				},
			],
		},
	],
};

export const health = {
	title: {
		text: {...cardTitleProps, children: '번호표 착용 및 기록칩 관련'},
	},
	uls: [
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children: `참가자는 수령한 공식 배번호표를 반드시 배에 부착하여야 하며, 부착하지 않거나 변형, 훼손된 것을 부착할 경우 대회에 참가하실 수 없습니다.
배번호표는 임의로 제작, 변형할 수 없습니다. 본 대회는 기록의 정확성을 위해 전자기록칩 시스템을 적용합니다.
※기록칩은 참가패키지와 함께 동봉됩니다. 번호표를 받은 후 칩이 포함되어 있는지를 반드시 확인 바랍니다.`,
					},
				},
				{
					content: {
						...ulsContentProps,
						children: `단체신청한 경우, 본인의 기록칩과 타인의 기록칩이 섞이지 않도록 구분하여 착용 바랍니다`,
					},
				},
				{
					content: {
						...ulsContentProps,
						children: `타인의 배번호 또는 기록칩을 착용한 경우 시상에서 제외됩니다.`,
					},
				},
			],
		},
	],
};

export const fail = {
	title: {
		text: {...cardTitleProps, children: '기록 계측 안내'},
	},
	uls: [
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children: `5km 코스는 전자칩에 의한 기록 계측은 하지 않습니다.
풀, 하프, 10km 코스는 기록의 정확성을 기하기 위해 칩(chip)을 사용한 무선인식 시스템을 적용합니다. 
다만 풀, 하프, 10km 코스의 순위 결정은 골인점을 통과한 순서에 의해 결정합니다. 출발점, 반환점, 골인점에 기록측정 매트가 설치됩니다.`,
					},
				},
			],
		},
	],
};

export const provide = {
	title: {
		text: {...cardTitleProps, children: '환불 규정'},
	},
	// subTitle: {
	// 	text: {...subTitleProps, children: '대회전'},
	// 	border: `1px solid ${colorPalette.primary}`,
	// },
	uls: [
		// {
		// 	listStyle: 'circle',
		// 	items: [
		// 		{
		// 			content: {
		// 				...ulsContentProps,
		// 				children: '기념품, 배번호, 안내책자',
		// 			},
		// 		},
		// 	],
		// },
		// {
		// 	listStyle: 'title',
		// 	items: [
		// 		{
		// 			title: {...ulsTitleProps, children: '풀/하프/10km'},
		// 			content: {
		// 				...ulsContentProps,
		// 				children:
		// 					'메달, 간식, 안내책자, 보험, 기념품, 배번호, 기록칩, 기록증',
		// 			},
		// 		},
		// 		{
		// 			title: {...ulsTitleProps, children: '5km'},
		// 			content: {
		// 				...ulsContentProps,
		// 				children: '메달, 간식, 안내책자, 보험, 기념품, 배번호',
		// 			},
		// 		},
		// 	],
		// },
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'환불 신청은 접수기간(2024년 10월 18일 금요일) 중에만 가능합니다.',
					},
				},
				{
					content: {
						...ulsContentProps,
						children:
							'환불 신청은 접수기간 중 환불 게시판에 올려주시기 바랍니다.',
					},
				},
				{
					content: {
						...ulsContentProps,
						children:
							'참가비 입금시 자동으로 입금확인이 되지 않으며, 입금 다음날(사무국 미운영일 제외) 확인이 가능합니다. (접수 마감일까지 미입금시 자동 접수 취소)',
					},
				},
				{
					content: {
						...ulsContentProps,
						children: `접수 마감 전 취소 시 환불이 가능합니다. 단, 은행수수료가 나올 경우 은행수수료를 공제하고 환불됩니다. \n(환불 소요기간은 약 1주일 소요)`,
					},
				},
				{
					content: {
						...ulsContentProps,
						children:
							'단체는 입금자명에 단체명 표기해 입금하시길 바랍니다.',
					},
				},
				{
					content: {
						...ulsContentProps,
						children:
							'공식 접수마감 이후 취소나 종목 변경 불가합니다.(연장접수기간 취소 및 환불 불가)',
					},
				},
				// {
				// 	content: {
				// 		...ulsContentProps,
				// 		children:
				// 			'접수마감 전 취소시 10% 공제 후 환불됩니다.(환불 소요기간은 약 1주일 소요)',
				// 	},
				// },
				{
					content: {
						...ulsContentProps,
						children:
							'참가자가 조회할 수 없는 명의로 입금하여 입금 확인이 되지 않을 경우, 참가할 수 없으며 참가비는 환불이 가능합니다.',
					},
				},
			],
		},
	],
};

export const onGoing = {
	// subTitle: {
	// 	text: {...subTitleProps, children: '대회중'},
	// 	border: `1px solid ${colorPalette.primary}`,
	// },
	// uls: [
	// 	{
	// 		listStyle: 'circle',
	// 		items: [
	// 			{
	// 				content: {
	// 					...ulsContentProps,
	// 					children: '주로 및 대회장 내 지급품 안내해드립니다.',
	// 				},
	// 			},
	// 			{
	// 				content: {
	// 					...ulsContentProps,
	// 					children: '급수대는 매 2.5km 간격으로 배치됩니다.',
	// 				},
	// 			},
	// 			{
	// 				content: {
	// 					...ulsContentProps,
	// 					children: '급수대에서는 물과 간식 등이 제공됩니다.',
	// 				},
	// 			},
	// 			{
	// 				content: {
	// 					...ulsContentProps,
	// 					children: '골인 후, 기록증과 간식이 제공됩니다.',
	// 				},
	// 			},
	// 		],
	// 	},
	// ],
};

export const after = {
	// subTitle: {
	// 	text: {...subTitleProps, children: '대회 후'},
	// 	border: `1px solid ${colorPalette.primary}`,
	// },
	// uls: [
	// 	{
	// 		listStyle: 'circle',
	// 		items: [
	// 			{
	// 				content: {
	// 					...ulsContentProps,
	// 					children: '골인지점 촬영 사진 제공',
	// 				},
	// 			},
	// 		],
	// 	},
	// ],
};

export const control = {
	title: {
		text: {...cardTitleProps, children: '경기 시간 제한'},
	},
	table: {
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '출발시간'},
			{...thProps, children: '경기종료'},
			{...thProps, children: '제한시간'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스'},
				{...tdProps, children: '09:00'},
				{...tdProps, children: '14:00'},
				{...tdProps, children: '5시간'},
			],
			[
				{...tdProps, children: '하프코스'},
				{...tdProps, children: '09:10'},
				{...tdProps, children: '12:10'},
				{...tdProps, children: '3시간'},
			],
			[
				{...tdProps, children: '10km'},
				{...tdProps, children: '09:20'},
				{...tdProps, children: '11:20'},
				{...tdProps, children: '2시간'},
			],
			[
				{...tdProps, children: '5km'},
				{...tdProps, children: '09:30'},
				{...tdProps, children: '10:30'},
				{...tdProps, children: '1시간'},
			],
		],
		descriptions: [
			{
				content: {
					children: `제한 시간이 넘었음에도 불구하고 주최 측 회송차량 승차를 거부한 채 계속 주행하던 도중에 발생하는 사고에 대한 모든 책임은 참가자 본인에게 있으며, 주최 측은 책임을 지지 않습니다.`,
				},
			},
		],
	},
};

export const emergency = {
	title: {
		text: {...cardTitleProps, children: '유의사항'},
	},
	uls: [
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'풀코스는 반드시 출발 후 5시간 이내에 완주하여야 합니다.',
					},
				},
				{
					content: {
						...ulsContentProps,
						children: `교통통제 시간은 시민과 참가자, 주최 측과 자원봉사자 간의 약속임에도 불구하고, 일부 주자들이 이러한 약속을 지키지 않아, 많은 민원이 발생하고 있습니다.
반드시 제한시간을 준수하시고, 5시간 이후에는 회송차량에 탑승하여, 유종의 미를 거둘 수 있도록 협조하여 주십시오.`,
					},
				},
			],
		},
	],
};

export const privacy = {
	title: {
		text: {...cardTitleProps, children: '개인정보 관리'},
	},
	uls: [
		{
			listStyle: 'circle',
			items: [
				{
					content: {
						...ulsContentProps,
						children:
							'참가 신청자의 보다 나은 서비스 제공을 위해 개인 정보를 보험사, 대회 협력사와 공유할 수 있습니다.',
					},
				},
				{
					content: {
						...ulsContentProps,
						children:
							'차기 대회 홍보시 문자메시지 발송용으로 활용됩니다.',
					},
				},
				{
					content: {
						...ulsContentProps,
						children:
							'그 외 다른 영리적인 목적으로 개인정보를 타인 또는 타 기업, 기관에 공개하지 않습니다.',
					},
				},
				{
					content: {
						...ulsContentProps,
						children: `다만, 신청자가 참가 유의사항 및 이용약관을 위배한 사람인 경우, 타인에게 법적인 피해를 주는 경우 혹은 미풍양속을 해치는 행위를 한 경우 등은\n법적인 조치를 취하기 위해 개인정보를 공개할 수 있습니다.`,
					},
				},
			],
		},
	],
};
