import {fontWeight, colorPalette, fontSize} from '../../../config';
import {
	cardTitleProps,
	ulsTitleProps,
	ulsContentProps,
	tdProps,
	thProps,
} from '../../common';
import {namImg1, namImg2, namImg3} from '../../../config/images';
import {Descriptions} from 'antd';
import {NoneLi} from '../../../Components/Atom/Ul/style';

export const mrnamprofile = {
	title: {
		text: {...cardTitleProps, children: '남승룡프로필'},
	},
};

export const inviteDetail = {
	title: {
		type: 'p',
		fontSize: {
			desktop: '24px',
			tablet: '24px',
			mobile: '24px',
		},
		fontWeight: fontWeight.medium,
		fontFamily: 'Gmarket',
	},
	content: {
		type: 'span',
		fontSize: {
			desktop: '18px',
			tablet: '16px',
			mobile: '16px',
		},
		color: colorPalette.primary,
		fontWeight: fontWeight.semiBold,
	},
	author: {
		type: 'span',
		fontSize: {
			desktop: '18px',
			tablet: '16px',
			mobile: '16px',
		},
		lineHeight: '30px',
		fontWeight: fontWeight.base,
		color: '#464646',
	},
};
export const profileimg = {
	profileimg01: {
		src: namImg1,
		alt: '남승룡이미지',
		borderRadius: '10px',
	},
	profileimg02: {
		src: namImg2,
		alt: '남승룡이미지',
		borderRadius: '10px',
	},
	profileimg03: {
		src: namImg3,
		alt: '남승룡이미지',
		borderRadius: '10px',
	},
};

export const profileHistoryWrap = [
	{
		title: {
			...inviteDetail.title,
			children: '· 출생',
		},
		ul: {
			listStyle: 'none',
			items: [
				{
					content: {
						...inviteDetail.content,
						children: '1912. 11. 23.',
					},
				},
				{
					content: {
						...inviteDetail.author,
						children: `전남 순천시 저전동 출생`,
					},
				},
			],
		},
	},
	{
		title: {
			...inviteDetail.title,
			children: '· 학력사항',
		},
		ul: {
			listStyle: 'none',
			items: [
				{
					content: {
						...inviteDetail.author,
						children:
							'- 순천공립보통학교 졸업 (현 순천남초등학교)\n- 일본 아사부(麻布)상업학교 졸업\n- 일본 메이지(明治)대학 졸업',
					},
				},
			],
		},
	},
	{
		title: {
			...inviteDetail.title,
			children: '· 선수경력',
		},
		ul: {
			listStyle: 'none',
			items: [
				{
					content: {
						...inviteDetail.content,
						children: '1930.',
					},
				},
				{
					content: {
						...inviteDetail.author,
						children: `제6회 조선신궁경기대회 10,000m 4위, 마라톤 2위`,
					},
				},
				{
					content: {
						...inviteDetail.content,
						children: '1931.',
					},
				},
				{
					content: {
						...inviteDetail.author,
						children: `제7회 조선신궁경기대회 마라톤 2위`,
					},
				},
				{
					content: {
						...inviteDetail.content,
						children: '1932.',
					},
				},
				{
					content: {
						...inviteDetail.author,
						children: `제8회 조선신궁경기대회 10,000m 1위, 마라톤 1위
제8회 전조선육상경기대회 5,000m, 10,000m 1위`,
					},
				},
				{
					content: {
						...inviteDetail.content,
						children: '1933.',
					},
				},
				{
					content: {
						...inviteDetail.author,
						children: `제9회 조선신궁경기대회 마라톤 2위
제20회 일본육상경기선수권대회 마라톤 2위`,
					},
				},
				{
					content: {
						...inviteDetail.content,
						children: '1934.',
					},
				},
				{
					content: {
						...inviteDetail.author,
						children: `제21회 일본육상경기선수권대회 10,000m 1위`,
					},
				},
				{
					content: {
						...inviteDetail.content,
						children: '1935.',
					},
				},
				{
					content: {
						...inviteDetail.author,
						children: `제9회 메이지신궁경기대회 마라톤 4위`,
					},
				},
				{
					content: {
						...inviteDetail.content,
						children: '1936.',
					},
				},
				{
					content: {
						...inviteDetail.author,
						children: `제11회 베를린올릭픽 마라톤 파견 최종선발전 1위
제11회 베를린올릭픽 마라톤 3위`,
					},
				},
				{
					content: {
						...inviteDetail.content,
						children: '1937.',
					},
				},
				{
					content: {
						...inviteDetail.author,
						children: `제51회 보스턴마라톤대회 12위`,
					},
				},
			],
		},
	},
	{
		title: {
			...inviteDetail.title,
			children: '· 일반경력',
		},
		ul: {
			listStyle: 'none',
			items: [
				{
					content: {
						...inviteDetail.content,
						children: '1947. ∼ 1963.',
					},
				},
				{
					content: {
						...inviteDetail.author,
						children: `제6회 조선신궁경기대회 10,000m 4위, 마라톤 2위`,
					},
				},
				{
					content: {
						...inviteDetail.content,
						children: '1953. ~ 1961.',
					},
				},
				{
					content: {
						...inviteDetail.author,
						children: `제7회 조선신궁경기대회 마라톤 2위`,
					},
				},
			],
		},
	},
	{
		title: {
			...inviteDetail.title,
			children: '· 수상내용',
		},

		ul: {
			listStyle: 'none',
			items: [
				{
					content: {
						...inviteDetail.content,
						children: '1970.',
					},
				},
				{
					content: {
						...inviteDetail.author,
						children: `국민훈장 모란장`,
					},
				},
				{
					content: {
						...inviteDetail.content,
						children: '2023.',
					},
				},
				{
					content: {
						...inviteDetail.author,
						children: `2023 대한민국 스포츠영웅`,
					},
				},
			],
		},
	},
];

export const news = {
	title: {
		type: 'p',
		fontSize: {
			desktop: '24px',
			tablet: '24px',
			mobile: '20px',
		},
		fontWeight: fontWeight.medium,
		fontFamily: 'Gmarket',
	},
	content: {
		type: 'p',
		fontSize: {
			desktop: '18px',
			tablet: '18px',
			mobile: '16px',
		},
		fontWeight: fontWeight.base,
		lineHeight: '30px',
	},
	author: {
		type: 'span',
		fontSize: {
			desktop: '18px',
			tablet: '18px',
			mobile: '16px',
		},
		fontWeight: fontWeight.base,
	},
};
export const newsWrap = {
	inviteBox: {
		title: {
			...news.title,
			children: '마라톤 영웅 남승룡',
		},
		content: {
			...news.content,
			children: `순천이 낳은 세계적인 마라토너인 故 남승룡 선생을 추모하기 위해 순천남승룡마라톤대회를 개최하고 있다. 한국마라톤 1세대를 대표하는 故 남승룡 선생은 일제강점기였던 1936년, 베를린 올림픽에서 금메달에 버금가는 값진 동메달을 획득한 세계적인 마라톤 선수이다.

그는 1912년 11월 23일 순천시 저전동에서 태어났다. 순천공립보통학교(현 순천남초등학교) 재학 시절, 외사촌 형이 운동회에서 일본인들과 경쟁해 마라톤 1위를 차지하여 모든 시민들의 열렬한 환영을 받는 것을 보고 마라톤에 깊은 관심을 갖기 시작하였다.

1936년 5월 11일, 베를린 올림픽 파견 마라톤 선수 최종 선발전에서 1위를 하여 손기정 선생과 함께 베를린 올림픽에 참가하였으며, 손기정 선생은 금메달, 남승룡 선생은 2시간 31분 42초로 은메달을 딴 하퍼 선수와 19초 차이로 값진 동메달을 획득하였다.

이 대회에서 남승룡 선생은 동메달을 획득하였음에도 자신의 가슴에 달린 일장기를 가리지 못하는 사실을 부끄러워 하였으며, 남달리 조국을 위한 신념이 강했던 그는 대회가 있을 때마다 각국의 기자들에게 자신이 일본인이 아닌 한국인임을 눈물로 호소함으로써 또 다른 독립운동을 전개하기도 하였다.

광복 이후, 1947년 보스톤마라톤대회에서 손기정 감독과 함께 선수단을 구성하였고 코치 겸 페이스메이커 선수로서 서윤복의 우승을 뒷받침했다.

남승룡 선생은 항상 새벽 5시에 일어나 등산과 체조 등으로 건강을 유지하였고, 틈틈이 여행을 즐기며 사는 영원한 육상인이자 체육인이었다. 1970년 국민훈장 모란장을 받았으며, 대한육상 경기연맹 이사(1947~1963)를 역임하고 전남대학교에서 후진을 지도(1953~1961)하였다.

선생의 조카 남청웅씨는 "남승룡 선생은 과거를 회상하고 현재를 생각하며 미래를 설계할 수 있기 때문에 항상 마라톤을 철학이라고 말했다"라고 전했다.

또 "남승룡 선생은 양정고등보통학교 시절 서울에서 고향 순천까지 하루에 200리(80km)에서 250리(100km)를 5일간 뛰고, 부모님의 심부름을 하기 위해 여수까지도 뛰는 등 달리기를 생활화하였다"라고 말했다.`,
		},
	},
};
export const newsWrap01 = {
	title: {
		text: {...cardTitleProps, children: '신문자료'},
	},
	inviteBox: {
		title: {
			...news.title,
			children: '남승룡과 김수근',
		},
		content: {
			...news.content,
			children: `20일 타계한 마라톤 선수 남승룡씨와 대성그룹 김수근 명예회장은 서로 활동했던 분야는 다르나 모범적 삶을 살다갔다. 똑같이 사회에 보탬이 되는 뜻 깊은 일을 했으면서도 자신을 내세우지 않고 조용히 갈무리하면서 살다 갔다는 점에서 닮은 점이 많다. 조금만 성공해도 금방 우쭐대고 자신을 내세우는 요즘 풍토에서 이들의 '절제된 삶'은 좋은 귀감이 됨직하다. 손기정씨와 함께 한국마라톤 1세대를 대표하는 남승룡씨는 흔히 '비운의 마라톤 영웅'으로 일컬어진다. 1936년 베를린 올림픽 마라톤에서 3위를 차지, 일제식민통치 밑에서 신음하던 국민들에게 「하면 된다」는 희망을 안겨주었지만 금메달을 딴 손기정씨의 뒷전에 머물러 있어야 했다.

항상 금메달의 그림자 같은 삶을 가난하게 이어가면서도 불평 한마디 없이 맡겨진 일에 충실하는 모습은 주위를 숙연케 했다. 손씨와 좋은 관계를 유지했고 사회적 지위에도 연연하지 않았다.

그의 사회적 지위는 대한육상연맹 이사가 전부였다. 이마저 후배에게 물려주고 응원자로 만족한 삶은 범인으로서는 쉽게 흉내낼 수 없다.

'대성연탄'으로 우리와 친근했던 김수근 명예회장은 오로지 한 우물만 판 정도의 경영인으로 기억된다. 54년간의 기업경영에서 대성을 16개 계열사를 거느린 재계 32위의 그룹으로 키웠으면서도 항상 에너지사업 외길이란 '초심'을 잃지 않았다. 요즘 재벌그룹의 기본조건처럼 된 건설회사 조차 갖지 않았다.

대성그룹의 16개 계열사는 모두 부채비율이 100%를 밑도는 것으로 알려졌다. 계열사 부채를 전부 합해도 1000억원이 되지 않는다고 한다.
김 명예회장의 '돌다리 두드리기식'의 건전경영이 돋보이는 대목이다. 이 같이 탄탄하고 검소한 경영은 요즘 구조개혁의 회오리에 휩쓸려 허덕이고 있는 재계에 좋은 교훈이 된다.

같은 날 세상을 떠난 두 사람의 이 같은 삶을 되돌아 보는 것은 이들이 남을 시기하지도 욕심부리지도 않고 정도를 걸었다는 점 때문이다.

벼락출세와 일확천금을 노리는 것이 당연시 되고 있는 세태에서 충분히 자랑할 수도 있는 사회적 업적을 이룩하고도 스스로를 낮추고 분수를 지킨 삶이 빛나 보인다.

인생이란 긴 마라톤경주에서 옆을 보지 않고 군더더기 없는 발걸음으로 주파한 두 사람의 인생성적표는 오래 기억하고 본 받을만한 가치가 있다.`,
		},
	},
};
export const newsWrap02 = {
	inviteBox: {
		title: {
			...news.title,
			children: '남승룡선생의 꿈',
		},
		content: {
			...news.content,
			children: `“조선의 아들들아, 이 말을 아는가. 다 같이 이 마음을 본받자. 서로가 이 뜻을 간직하자. 그들이 이 땅을 떠날 때 ‘죽어도 죽어도 이기고 오겠다'는 말! 과연 세계에 빛나게 거룩히 이루었으니….” “우리는 먼저 나를 찾아야 되겠다. 나를 찾지 않고 나를 어찌 말할 것이며 또 나를 갈고 깎지 않고 또 누구에게 견주어 말할 것인가.” 1936년 베를린 올림픽 마라톤에서 손기정과 남승룡이 우승과 3위를 했을 때 동아일보는 그렇게 그들의 위업을 기렸고 환영했다.
그로부터 65년. 용기와 희망도 주었고, 위안도 됐던 우리 마라톤의 두 거목 중 남승룡 선생이 엊그제 타계했다. 손기정 선생의 월계관이 우리에게 안겨준 감격이 너무도 컸기 때문에 남승룡 선생은 늘 손기정 선생의 그늘에 가려 살았다. 1960년대 말 ‘젊은 사람들이 지도자로 나서야 육상이 발전한다'며 체육계를 떠난 뒤에는 바깥 나들이도 피했다. 그래서 선생은 세상의 시각으로는 ‘비운의 마라토너'였다.

그러나 달리기는 선생의 꿈이었다. 전남 순천에서 태어나 보통학교 때 전국대회에서 2위를 한 선생은 가정형편상 진학하지 못했다. 하지만 달리기를 하고 싶어 뒤늦게 상경해 협성학교를 다니다 육상명문 양정고보로 전학했다. 후견인의 도움으로 일본의 학교로 옮긴 선생은 1935년 11월 올림픽대표 예선전에서 4위를 했다. 손기정 선생이 1위였다. 그러나 선생은 이듬해 5월 최종선발전에서는 1위를 했다.

선생의 마라톤은 후반에 강했다. 베를린올림픽에서 반환점을 돌 때는 33위였으나 30㎞ 지점에서는 16위로, 34㎞지점에서는 6위로 올라서며 동메달리스트가 됐다. 선생이 정말 후반에 강했음을 보여준 건 1947년이었다. 36세의 나이로 보스턴마라톤에 코치 겸 선수로 출전한 선생은 태극마크를 가슴에 달고 12위를 했다. 서윤복 선생이 우승한 대회였다. 선생은 기동이 불편해진 1990년 78세까지 달리기를 했다. 강인한 체력도 중요하지만 그보다는 어렵고 괴로운 고비고비를 넘겨야 하는 끈질긴 정신력의 스포츠가 바로 마라톤이다. 마라톤 인생을 살아온 선생의 명복을 빈다.`,
		},
		author: {
			...news.author,
			children: '[출처:한국일보 2001/02/21]',
		},
	},
};
export const newsWrap04 = {
	inviteBox: {
		title: {
			...news.title,
			children: `한국마라톤 '큰 별' 지나`,
		},
		content: {
			...news.content,
			children: `한국마라톤의 큰 별이 떨어졌다.
1936년 베를린올림픽 마라톤에서 동메달을 획득，식민지 조국에 무한한 가능성과 희망을 안겼던 남승룡옹(사진)이 20일 숙환으로 별세했다. 향년 89세.

지난달 12일 심부전증으로 서울 경찰병원 중환자실에 입원한 뒤 산소마스크에 의지한 채 생명을 유지해 왔던 남옹은 끝내 병마를 이기지 못하고 이날 오전 10시25분 영광과 좌절의 마라톤 인생을 마감했다.

1912년 전남 순천에서 태어난 고인은 손기정옹(89)과 함께 한국마라톤의 1세대였지만 지금까지 손옹의 그늘에 가려 있었던데다 나서기를 싫어하는 성격으로 대외 활동을 하지 않아 널리 알려지지는 않았다.36세에 출전한 47년 보스턴마라톤에서는 해방된 조국의 태극기를 달고 당당히 10위에 랭크，우승자인 서윤복씨와 함께 마라톤 강국의 자존심을 살렸다.

47년 1월부터 63년 9월까지 대한육상연맹 이사로 활동했던 고인은 보통학교 6학년 때 전남대표로 조선신궁대회에 나가 1만m 4위， 마라톤 2위를 해 신동으로 불렸을 정도로 재능이 뛰어났다. 서울협성실업학교와 양정고보를 거쳐 일본 아사부중학교로 전학했던 고인은 메이지대학 입학 이후 재능을 높이 산 귀족 기다바라케의 후원을 받았고,베를린올림픽 파견 대표 선발전에서는 1위로 골인,손기정씨와 함께 올림픽 출전권을 따냈다.

유족으로는 미망인 소갑순(81)씨와 2남 4녀가 있다. 빈소는 서울강남시립병원 영안실 12호이며 발인은 22일 오전이다.(02)3430-0456`,
		},
		author: {
			...news.author,
			children: '[출처:조선일보 2001/02/21]',
		},
	},
};
export const newsWrap05 = {
	inviteBox: {
		title: {
			...news.title,
			children: `무관심속 사라진 베를린 동메달 남승룡`,
		},
		content: {
			...news.content,
			children: `남옹은 1936년 8월9일 열린 베를린올림픽 마라톤경기에서 동갑내기 손기정옹과함께 메달을 따내 일제 치하에서 신음하던 한민족에게 한줄기 희망을 던졌던 '살아있는 역사'였다.문제는 메달의 색깔.

금메달리스트인 손옹이 해방 이후 '민족의 영웅'으로 대접받아온 반면 동메달을받아쥔 남옹은 화려한 조명 속에 있던 손옹의 그늘에 가려 세인들의 관심 밖에서 쓸쓸한 여생을 보냈다.

앞에 나서기 싫어하는 성격 탓에 육상 연맹 이사와 전남대 체육학과 교수를 지낸 것 외에 별다른 활동이 없어 그늘을 더했다.
이 때문에 별명도 '은둔하는 영웅'이었던 남옹은 30대 후반의 나이로 1947년 보스턴대회에서 10위로 골인하는 등 꾸준한 선수생활로 후배들의 귀감이 됐고 진정한 마라토너임을 자부하며 살았다.

절친한 친구인 손옹도 “같이 고생했는데 나만 대접받는 것 같아 미안하다”며 “빚을 진 기분”이라고 입버릇처럼 말하곤 했다.
96년 가을 베를린올림픽 마라톤 제패 60주년 기념식 때 후배 육상인들이 손옹의금메달과 똑같은 모양의 금메달을 제작해 남옹에게 전달한 뒤 마음속의 빚을 털어버렸을 정도.

강남시립병원의 한 영안실에 안치된 남옹의 시신을 앞에 놓고 막내딸 순옥(53)씨가 한 말은 많은 여운을 남긴다.
“아버지께서 손선생님의 그늘에 가렸다고 생각하지는 않아요. 1등이 대우받는세상임은 틀림없지만 마라톤 발전을 위해 노력한 아버지는 영원히 존경받을 겁니다. ”`,
		},
		author: {
			...news.author,
			children: '[출처:조선일보 2001/02/21]',
		},
	},
};
export const newsWrap06 = {
	inviteBox: {
		title: {
			...news.title,
			children: `한국마라톤 '큰별' 남승룡 별세`,
		},
		content: {
			...news.content,
			children: `한국마라톤의 큰 별이 졌다.
1936년 베를린올림픽 마라톤에서 동메달을 획득, 식민조국의 민족적 자긍심을 일깨웠던 남승룡(南昇龍)옹이 20일 오전 10시25분께 서울경찰병원에서 숙환으로 타계했다. 향년 89세.

평소 심부전증을 앓고 있던 남옹은 지난달 12일 갑작스럽게 혼수상태에 빠져 경찰병원 중환자실에 입원한 뒤 1개월 넘게 산소마스크에 의지해왔으나 끝내 일어나지 못했다.

1912년 전남 순천에서 태어난 고인은 동갑내기이자 양정고보 1년 후배인 손기정옹(89)과 함께 한국마라톤의 중흥을 이끈 마라톤 1세대. 보통학교 6학년 때 이미 도대표로 조선신궁대회 1만ｍ 4위, 마라톤 2위를 차지했던 남옹은 33년 조선신궁대회 마라톤에 손기정에 이어 2위를 차지하면서 두각을 나타냈다.

36년 5월 올림픽 파견 최종선발전에서 1위를 차지한 남옹은 같은해 8월 일장기를 달고 출전한 베를린올림픽에서 2시간31분32초의 기록으로 동메달을 땄다. 남옹은 또 해방후인 47년 36세의 나이에도 불구하고 보스턴마라톤에 출전, 10위에 오르는 기염을 토하며 우승자인 서윤복씨와 함께 마라톤 한국의 위상을 세웠다. 평생 손기정씨의 그늘에 가려 크게 빛을 못본 고인은 47년부터 63년까지 대한육상경기연맹 이사로 활동했고 전남대 교수를 역임했다.

유족으로는 부인 소갑순씨(81)와 미국에 거주하는 큰아들 은기씨(61) 등 2남4녀를 두고 있다.

빈소는 서울강남시립병원 영안실 12호(02－3430－0456). 발인은 22일 오전.`,
		},
		author: {
			...news.author,
			children: '[출처:경향신문 2001/02/21]',
		},
	},
};
export const newsWrap07 = {
	inviteBox: {
		title: {
			...news.title,
			children: `1936년 베를린올림픽 마라톤 동메달리스트 남승룡 별세..`,
		},
		content: {
			...news.content,
			children: `손기정씨와 함께 1936년 8월 9일 베를린에서 열린 제11회 베를린올림픽에서 2시간31분42초의 기록으로 동메달을 획득, 일제의 억압 속에 짓눌려 있던 우리 민족에게 민족정신을 심어 주었던 남승룡씨가 20日 오전 10시 25분 서울경찰병원 중환자실에서 향년 89세, 평소의 지병인 심부전증으로 별세했다.
올림픽 마라톤 3위를 했으면서도 손기정선수의 그늘에 가려 크게 빛을 보지 못했던 남승룡선수는 광복을 맞은 후에도 1947년에는 보스톤마라톤대회에는 30대 후반의 나이에도 불구, 서윤복씨(당시 우승자)와 함께 출전해 10위로 골인하는 등 계속 마라톤 발전을 위해 정성을 쏟아 후배를 지도해 왔으며 우리에게 귀감이 되어 왔다.

보통학교 6학년때 이미 전남대표로 조선신궁대회에 나가 1만m 4위, 마라톤 2위를 해 신동으로 불렸던 남선수는 서울협성실업학교, 양정고보를 거쳐 일본 아사부중학교로 전학했다. 1933년 10월 10일 제9회 조선신궁대회 마라톤 경기에서 처음으로 동갑인 손기정선수와 격돌, 손선수는 2시간39분34초로 1위, 남선수는 2시간 30분51초로 2위를 차지하면서 두각을 드러냈다.

아사부중학을 마친 남선수는 일본 메이지대학에 입학했고, 재능을 눈여겨 본 일본의 귀족 기다바라케가 후견인이 되어 지원하는 가운데 베를린올림픽에 출전, 2위(영국 하퍼. 2시간31분23초)와 80m차이로 3위를 차지했다.

□ 빈 소: 서울강남시립병원 영안실 12호(02-3430-0456)
□ 발 인: 2월 22일(목)
□ 장 지: 용인공원묘지(미정)`,
		},
		author: {
			...news.author,
			children: '[출처:대한육상경기연맹 자료]',
		},
	},
};
