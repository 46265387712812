import {fontWeight, colorPalette, fontSize} from '../../../config';
import {
	cardTitleProps,
	ulsTitleProps,
	ulsContentProps,
	tdProps,
	thProps,
} from '../../common';
import {inviteImg} from '../../../config/images';
import {namImg4} from '../../../config/images';

export const invite = {
	title: {
		text: {...cardTitleProps, children: '추모의 글'},
	},
};
export const invite01 = {
	title: {
		text: {...cardTitleProps, children: '남승룡기념탑'},
	},
};
export const profileimg = {
	profileimg04: {
		src: namImg4,
		alt: '남승룡이미지',
		borderRadius: '10px',
	},
};

export const news = {
	title: {
		type: 'p',
		fontSize: {
			desktop: '24px',
			tablet: '24px',
			mobile: '20px',
		},
		fontWeight: fontWeight.medium,
		fontFamily: 'Gmarket',
	},
	content: {
		type: 'p',
		fontSize: {
			desktop: '18px',
			tablet: '18px',
			mobile: '16px',
		},
		fontWeight: fontWeight.base,
		lineHeight: '30px',
	},
	author: {
		type: 'span',
		fontSize: {
			desktop: '18px',
			tablet: '18px',
			mobile: '16px',
		},
		fontWeight: fontWeight.base,
	},
};
export const newsWrap = {
	inviteBox: {
		title: {
			...news.title,
			children: '최선을 다했던 마라토너 남승룡',
		},
		content: {
			...news.content,
			children: `그 분이 돌아가신 지도 어느덧 며칠이 흘러갔습니다. 그분이 최고였다면 방송사들도 특집방송을 준비하고 부산을 떨었으련만, 그분에 관한 이야기는 신문과 스포츠 뉴스에 간략하게 언급되는데 그쳤습니다. 그리고, 이제는 아무도 그분에 관한 이야기를 하지 않지요.
남승룡. 우리 초등학교 친구들은 그가 누구인지조차 모를 것입니다. 1등이 아니었으니까요. 예전 삼성그룹의 기업광고에 이런 광고문구가 있었습니다. '최고가 아니면, 아무도 기억하지 않는다. ' 정말 그런 것일까요?

남승룡 선수는 손기정 선수와 함께 36년 베를린 올림픽 마라톤 종목에 참가해 동메달을 땄습니다. 하지만, 남승룡 선수의 기량이 손기정 선수에 비해 뒤떨어진 것은 아니었습니다. 오히려 남 선수의 고향사람들은 손기정 선수보다 실력이 더 뛰어났지만, 그 날의 운이 없었을 뿐이라고 믿고있지요.

사실 고향사람들의 믿음이 틀린 것만은 아니었습니다. 남승룡 선수는 1932년 전 일본 마라톤선수권대회, 1933년 극동선수권대회, 1934년 일본건국국제마라톤 대회에서 모두 1위를 했으니까요. 베를린 올림픽에 참가할 일본 대표를 선발하는 최종예선에서 남승룡 선수는 1위, 손기정 선수는 2위, 스즈끼 선수는 3위를 차지했습니다. 하지만, 마라톤에 참가하는 각 나라의 선수는 3명으로 한정되어 있어서, 일본은 조선인 2명, 일본인 2명으로 후보를 정해서 베를린으로 보냈지요. 베를린 현지에서 벌어진 재선발전에서 손기정 선수가 1위 , 남승룡 선수가 2위를 차지해, 어쩔 수 없이 일본은 조선인 2명, 일본인 1명을 마라톤에 참가시켰습니다.

손기정 선수와 남승룡 선수는 시합 당일의 컨디션에 따라 성적이 달라질 정도로 엇비슷했지요. 마라톤 당일의 컨디션은 손기정 선수가 훨씬 좋았나 봅니다. 손기정 선수는 2시간 29분 19초 올림픽 신기록으로 1등을 했으니까요. 남승룡 선수는 30킬로미터 지점까지 30위에 불과했지만 가장 어려운 지점인 비스마르크 언덕에서 투혼을 발휘해 2시간 31분 43초의 기록으로 3위를 했습니다.

마라톤으로 나라 잃은 설움을 풀었던 남승룡 선수는 이후 해방된 조국에서도 우리나라 마라톤 발전을 위해 여러 일을 했습니다. 하지만, 보통 사람들은 그를 곧 잊어버렸지요. 1등이 아니었기 때문입니다. 1등을 좋아하는 우리네 마음은 올림픽이나 여러 국제 경기를 하면 금새 드러납니다. 올림픽은 참가하는 것만으로도 커다란 영광이라는데, 우리는 금메달을 딴 선수들에게만 찬사를 보내고, 그렇지 못한 선수들에게는 투지가 부족했다느니 하며 야유를 보내고 있으니까요. 세상은 승자와 패자만이 존재하는 것이 무서운 전쟁터가 아닙니다. 서로가 선의의 경쟁을 하고, 또 뒤쳐지는 사람에게 격려를 하며 살아가는 것이지요.

여담으로 어느 신문에도 나오지 않은 이야기 하나 할까합니다. 남승룡 선수에게는 남기룡이라는 동생이 있었다고 합니다. 남승룡 선수가 마라톤을 시작하게 된 동기가 외사촌형이 마라톤에서 1등 하는 것을 보고 나서였다니까, 집안이 모두 장거리 달리기에 소질이 있었나 봅니다. 이런 집안에서 태어난 동생 남기룡도 올림픽 마라톤 선수로 선발되었지요. 하지만, 형과는 달리 남기룡은 올림픽에 출전할 수 없었습니다. 2차세계대전이 일어나 올림픽이 열리지 못했기 때문입니다. 이는 남기룡에게 큰 한이 되었지요. 후일 남기룡은 주변사람들에게 이런 말을 했다고 합니다.

"형은 하늘을 오르는 용(昇龍)이어서 올림픽 메달리스트가 되었고, 나는 일어서는 용(起龍)이어서 올림픽 출전 자격만 얻었다."

시대를 잘못 타고난 영웅이 어찌 이들 뿐이겠습니까?`,
		},
	},
};
export const newsWrap01 = {
	inviteBox: {
		title: {
			...news.title,
			children: '2등은 아무도 기억해주지 않습니다...정말?',
		},
		content: {
			...news.content,
			children: `지난 2월 20일 오전 10시 25분 서울 문정동 경찰병원에서 돌아가신 분이 계십니다. 물론 저와 가까운 분은 아니랍니다. 그 분은 저를 아시지도 못하지요. 그리고 저 또한 그 분에 대해서 그다지 잘 알고 있는 편이 못 된답니다. 그 분이 누군고 하니... 바로 1936년 베를린 올림픽 마라톤에서 동메달을 획득한 남승룡 옹이십니다.

향년 89세. 우리 할머니보다 열 살이 더 많으시더군요. 저하곤 딱 50 살이 차이나는 울 할머니. 할머니 연세를 깜박할 때면 저는 얼른 제 나이에 50을 더해서 할머니의 연세를 정확히 되짚었답니다. 엄마와 아빠의 나이는 곧잘 틀리면서도 할머니의 연세는 희한하게도 딱 맞추는 이유가 여기에 있지요.

그 다음날 한 일간지에 실린 기사를 보니, 임종을 지킨 부인 소갑순 여사가 눈물을 흘리시며 "아무리 1등만 기억되는 세상이지만, 그동안 3등인 남편이 걸어온 삶은 영광보다는 좌절이 컸습니다..." 고 하셨다나요? 그리고 이어지는 기사에 일제강점기 '금메달에 버금가는 값진 동메달'을 따내 민족의 억눌린 한을 풀어낸 영웅이었지만, 금메달을 딴 손기정 옹에 밀려 '양지'보다는 '음지'에서 한평생을 보낸 비운의 마라토너라고 소개를 했더군요.

제가 남승룡이란 분을 처음으로 알게 된 것은 초등학교 시절이었습니다. 티비에서 광복절 기념으로 1936년 베를린 마라톤에서 메달을 딴 손기정과 남승룡의 다큐드라마를 제작방송했지요. 당시 전 그 드라마를 보며, 남승룡이란 사람에게 상당한 호감을 갖게 되었습니다. 기량이 손기정에 비해 떨어지는 게 아니었는데, 경기 당일날 안타깝게도 3등에 머물러서 역사의 뒤안길에 묻히게 된 안타까운 영웅이란 생각이 들었거든요. (그 어린 나이에 참 대단하다구요?? 아마도 남승룡역으로 나온 사람이 손기정역으로 나온 사람보다 더 잘생겨서 그런 생각을 했을 거예요.--;;) 그 기억이 워낙 강렬해서 그 뒤로 저는, '마라톤' 하면 그 유명한 손기정보다는 남승룡이 먼저 떠올랐지요. 그런데 왜 세상에선 손기정 타령만 하는 걸까? 난 남승룡이 보고 싶은데.... 그러면서 서서히 잊어갔지요.

대학교 들어와 어느날 티비 앞에 앉았더니 삼성에서 이런 광고를 해대더군요. 1등만이 기억되고, 2등은 아무도 기억해주지 않는다는... 거참 웃기는... 하지만.... 아주 섬찟한 광고였지요.

그 광고 보며 제일 먼저 떠오른 것은 바로 잊혀져가던 마라톤 영웅 남승룡이었습니다. 그리고 고등학교 3학년 때, 딱 한 번 1등 해보고 반에서 늘 2등을 했던 제 자신을 떠올렸지요. 1등 하는 걸 목표에 두고 공부한 적은 없었지만 그 무시무시한 삼성광고를 볼 때면 그때의 생각이 나곤 했습니다. 그럼 고등학교 때 동창들은 아무도 날 기억해주지 않겠네? 흥!

세상이 정말 이상하게 돌아갑니다. 왜 1등만이 기억되어야 한다고 강조하는 건지, 왜 1등만이 세상을 바꿀 수 있다고 말하는 건지, 왜 1등이 아니면 아무 것도 아니라고 하는 건지..... 그 1등, 1등 하는 소리에 온 국민이 1등병에 걸려서 자신을 괴롭히는 게 아닌가 하는 생각을 해보았습니다. 남승룡 옹은 이러한 싸구려 매스컴의 희생양이구요. 그 분은 선수생활을 마감한 이후에도 전남대 체대 교수를 지내기도 하며 비록 언론을 떠들썩하게 장식하진 않았지만 그럭저럭 꾸준하게 마라톤 인생을 살아오신 것 같았습니다. 오히려 언론에 나서는 걸 피하고 조용한 생활을 원하셨던 것 같더군요.

그럼에도 이번에 그 분이 돌아가시고 나서 언론은 마치 퍼뜩 기억났다는 듯이 아무도 기억해주지 않는 쓸쓸한 죽음이네, 대접받지 못한 영웅이네 하며 비극적인 조명을 비추는 것이었습니다. 그 분나름대로 열심히 살아오셨고, 비록 하찮지만 저같은 사람은 그 분의 삶에 대해 궁금해하고 그랬는데도 말이죠. 이런 언론의 모습이 제게는 2등은 아무도 기억해주지 않는다는 그 섬찟한 문구를 확연하게 뒷받침해주는 증거를 제시하는 것처럼 느껴지는 건 왜일까요?

이제 제발 2등은 기억되지 못한다는 말을 나불거리며, 멀쩡한 사람을 불쌍하게 만들거나 이용하지 말았으면 합니다. 남승룡 옹은 정말 자신의 본분대로 열심히 살다 가신 저의 멋진 영웅이셨습니다.`,
		},
		author: {
			...news.author,
			children: `[출처:레이디경향 2001년 5월호]`,
		},
	},
};
export const newsWrap02 = {
	inviteBox: {
		title: {
			...news.title,
			children: '기념탑 세움 목적',
		},
		content: {
			...news.content,
			children: `어두울수록 더욱 빛나는 별이 있게 마련이다. 높고 험한 산을 애써 오르는 산악인처럼 한 시대를 올곧고 꾸준하게 달려와 마침내 우리 곁에 우뚝 선 마라토너가 있다. 암울했던 일제 강점기에 굴하지 않고 꺾이지 않는 당당한 모습으로 우리 민족에게 한줄기 빛이 되어 주었던 사람. 남승룡(1912~2001). 바로 순천 출신의 영원한 마라토너이자 민족 체육인의 표상이다.

1936년 베를린 올림픽 대회에서 동메달을 획득하여 금메달리스트 손기정과 함께 민족의 심금을 울려 주었고, 36살 적잖은 나이에 꿈에도 그리던 태극마크를 달고 보스턴 마라톤대회에서는 10위를 차지하는(당시 우승자는 서윤복) 투혼을 발휘하여 나라 잃은 민족의 슬픔을 달래주는데 큰 공헌을 하였다.

보통학교(순천 남초등학교) 재학 시절부터 마라톤 신동으로 이름을 날렸고, 올림픽 영웅이면서도 조용히 야인처럼 살다 2001년 2월 20일(향년 89세) 숙환으로 별세하였다.

불굴의 강인한 투지로 식민지 조국과 순천인에게 큰 희망이 되어 준 그의 투혼을 높이 기리고 순천시민의 뜻을 모아 이 탑을 여기에 세운다. 오늘 그리고 훗날 그처럼 꿋꿋하고 당당하게 앞날을 열고 달려가는 이들이 있어 결코 '쓸쓸하지 않은 영웅'으로 우리 곁에 영원히 자리할 것이다. 저 하늘 한 개 빛나는 별빛으로.`,
		},
	},
};
export const newsWrap03 = {
	inviteBox: {
		title: {
			...news.title,
			children: '기념탑의 모습',
		},
		content: {
			...news.content,
			children: `‘남승룡 추모 순천시민의 탑’의 전체적인 형태는 배가 돛을 달고 힘차게 항해하는 모습에서 착안하였다. 부분별로는 크게 기단부(2계단)와 중간부(부조), 상단부(비상하는 모습)로 나눌 수 있다. 기단부의 형태는 배와 같은 모형이고 단의 끝 부분에 경사각으로 약간 주어서 상승감(집중감)을 상단부로 이어지게 하였다.

중간부(벽부조)는 삼산이수의 지리적 환경 속에 뿌리를 박고 끈질기게 살아온 동안 이 지역에 있었던 3·1운동과 동학혁명, 여·순사건 등 역사성을 반영하는 측면에서 기층민의 모습과 그것을 바탕으로 희망찬 미래를 열어가는 밝은 시민의 모습을 표현했다. 그리고 바로 탑 기둥에는 이 지역 출신인 남승룡 선수가 나라 잃은 슬픔 속에서도 강인한 정신을 바탕으로 베를린 올림픽에서 당당히 3위를 차지하는 모습을 새김으로써 그분의 정신을 기리고자 하였다.

상단부(비상하는 모습)는 이러한 지역민의 힘이 한데 모아져 순천의 앞날이 인간과 자연이 조화를 꾀하면서 밝고 희망찬 미래를 열어가는 비상하는 모습을 담고 있다.`,
		},
	},
};
